import initialState from 'store/initialState';
import {
	APP_DOCK_NAVIGATION,
	APP_UNDOCK_NAVIGATION,
	ADD_TOAST,
	GET_LATEST_RX_SUCCESS,
	REMOVE_TOAST,
	CLEAR_TOASTS,
	CREATE_RX_DASHBOARD_SUCCESS,
} from 'constants/actionTypes';

export default (state = initialState.dashboard, action = null) => {
	switch (action.type) {
		case APP_DOCK_NAVIGATION:
			return {
				...state,
				isNavDocked: true,
			};

		case APP_UNDOCK_NAVIGATION:
			return { ...state, isNavDocked: false };

		case ADD_TOAST:
			return { ...state, toasts: [...state.toasts, action.toast] };

		case REMOVE_TOAST:
			return {
				...state,
				toasts: state.toasts.filter(
					(toast) => toast.id !== action.toast.id,
				),
			};

		case CLEAR_TOASTS:
			return {
				...state,
				toasts: [],
			};

		case GET_LATEST_RX_SUCCESS:
			return {
				...state,
				prescriptions: action.data,
			};

		case CREATE_RX_DASHBOARD_SUCCESS:
			return {
				...state,
				prescriptions: [action.data, ...state.prescriptions],
			};

		default:
			return state;
	}
};
