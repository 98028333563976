import React from 'react';
import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiSpacer,
	EuiSuperSelect,
	EuiFieldPassword,
} from '@elastic/eui';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import DropdownOptWSub from 'components/DropdownOptWSub';

const lowerCaseRegex = new RegExp('^(?=.*[a-z])');
const upperCaseRegex = new RegExp('^(?=.*[A-Z])');
const numericRegex = new RegExp('^(?=.*[0-9])');
const specialCharacterRegex = new RegExp('^(?=.*[.!@#$%^&*])');

const UserSchema = Yup.object().shape({
	firstName: Yup.string().required('First Name required'),
	lastName: Yup.string().required('Last Name required'),
	username: Yup.string()
		.min(4, 'Username must be minimum of 4 characters')
		.max(50, 'Too Long!')
		.required('Username Required'),
	email: Yup.string().email('Invalid email').required('Email Required'),
	password: Yup.string()
		.min(8, 'The password must be at least eight (8) characters.')
		.matches(
			lowerCaseRegex,
			'The password must contain at least one (1) lowercase alphabetical character.',
		)
		.matches(
			upperCaseRegex,
			'The password must contain at least one (1) uppercase alphabetical character.',
		)
		.matches(
			numericRegex,
			'The password must contain at least one (1) numeric character.',
		)
		.matches(
			specialCharacterRegex,
			'The password must contain at least one (1) special character.',
		),
	confirmPassword: Yup.string()
		.oneOf(
			[Yup.ref('password'), null],
			'Make sure to re-enter the same exact password.',
		)
		.required('Please re-enter your password'),
	s2License: Yup.string().when('role', {
		is: (value) => value === 'Pharmacist',
		then: Yup.string().required('License Number Required'),
	}),
});

const AddUser = ({ addUser, isLoading, onClose, setError, visible }) => {
	const { t } = useTranslation();

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setErrors,
		setFieldError,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			username: '',
			password: '',
			confirmPassword: '',
			email: '',
			firstName: '',
			middleName: '',
			lastName: '',
			s2License: '',
			role: 'administrator',
			description: '',
		},
		validateOnBlur: true,
		validateOnChange: false,
		validationSchema: UserSchema,
		onSubmit: async (data) => {
			try {
				addUser(data);
			} catch (err) {
				// set error message from api request failure
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				resetForm({ values: initialValues });
			}
		},
	});

	const handleValidation = (data, field) => {
		const err = { ...errors };
		const isPasswordField = field === 'password';

		UserSchema.validateAt(field, data, { abortEarly: !isPasswordField })
			.then(() => {
				delete err[field];
				setErrors(err);
			})
			.catch((e) => {
				if (isPasswordField) return setFieldError(field, e.errors);
				return setFieldError(field, e.errors);
			});
	};

	const {
		confirmPassword,
		email,
		firstName,
		lastName,
		middleName,
		password,
		role,
		s2License,
		username,
	} = values;

	const roleOpts = [
		{
			value: 'administrator',
			inputDisplay: t('users.administrator'),
			dropdownDisplay: (
				<DropdownOptWSub
					// description={t('users.administratorDescription')}
					title={t('users.administrator')}
				/>
			),
		},
		{
			value: 'pharmacist',
			inputDisplay: t('users.pharmacist'),
			dropdownDisplay: (
				<DropdownOptWSub
					// description={t('users.pharmacistDescription')}
					title={t('users.pharmacist')}
				/>
			),
		},
	];

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Order"
			onClose={() => {
				onClose();
				resetForm({ values: initialValues });
			}}
			size="s"
			// TODO: move to stylesheet
			style={{ zIndex: 0 }}
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add User</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFlexItem grow={false}>
						<EuiFlexItem>
							<EuiFlexGroup>
								<EuiFlexItem>
									<EuiText>
										<h3>User Information</h3>
									</EuiText>
									<EuiSpacer />
									<EuiFormRow
										error={
											touched.username && errors.username
										}
										isInvalid={
											touched.username &&
											!!errors.username
										}
										label={t('users.username')}
										type="column"
									>
										<EuiFieldText
											data-testid="username-input"
											id="username"
											isInvalid={
												touched.username &&
												!!errors.username
											}
											name="username"
											onChange={handleChange}
											value={username}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={touched.email && errors.email}
										isInvalid={
											touched.email && !!errors.email
										}
										label={t('users.email')}
									>
										<EuiFieldText
											data-testid="email-input"
											id="email"
											isInvalid={
												touched.email && !!errors.email
											}
											name="email"
											onChange={handleChange}
											value={email}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={errors.password}
										isInvalid={!!errors.password}
										label={t('users.password')}
									>
										<EuiFieldPassword
											data-testid="password-input"
											id="password"
											isInvalid={!!errors.password}
											name="password"
											onChange={(e) => {
												setFieldValue(
													'password',
													e.target.value,
												);
												handleValidation(
													{
														password:
															e.target.value,
													},
													'password',
												);
											}}
											placeholder="Password"
											value={password}
										/>
									</EuiFormRow>

									<EuiFormRow
										error={errors.confirmPassword}
										isInvalid={!!errors.confirmPassword}
										label="Confirm Password"
									>
										<EuiFieldPassword
											data-testid="confirmPassword-input"
											id="confirmPassword"
											isInvalid={!!errors.confirmPassword}
											name="confirmPassword"
											onChange={(e) => {
												setFieldValue(
													'confirmPassword',
													e.target.value,
												);
												handleValidation(
													{
														password:
															values.password,
														confirmPassword:
															e.target.value,
													},
													'confirmPassword',
												);
											}}
											placeholder="Confirm Password"
											value={confirmPassword}
										/>
									</EuiFormRow>
									<EuiSpacer size="xl" />
									<EuiFormRow
										error={
											touched.firstName &&
											errors.firstName
										}
										isInvalid={
											touched.firstName &&
											!!errors.firstName
										}
										label="First Name"
									>
										<EuiFieldText
											data-testid="firstName-input"
											id="firstName"
											isInvalid={
												touched.firstName &&
												!!errors.firstName
											}
											name="firstName"
											onChange={handleChange}
											placeholder="First Name"
											value={firstName}
										/>
									</EuiFormRow>
									<EuiFormRow
										// error={errors.middleName}
										// isInvalid={!!errors.middleName}
										label="Middle Name"
									>
										<EuiFieldText
											data-testid="middleName-input"
											id="middleName"
											// isInvalid={!!errors.middleName}
											name="middleName"
											onChange={handleChange}
											placeholder="Middle Name"
											value={middleName}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={
											touched.lastName && errors.lastName
										}
										isInvalid={
											touched.lastName &&
											!!errors.lastName
										}
										label="Last Name"
									>
										<EuiFieldText
											data-testid="lastName-input"
											id="lastName"
											isInvalid={
												touched.lastName &&
												!!errors.lastName
											}
											name="lastName"
											onChange={handleChange}
											placeholder="Last Name"
											value={lastName}
										/>
									</EuiFormRow>
									<EuiSpacer size="xl" />
									<EuiFormRow label={t('users.role')}>
										<EuiSuperSelect
											data-testid="role-input"
											id="role"
											// isInvalid={!!errors.role}
											name="role"
											onChange={(data) =>
												setFieldValue('role', data)
											}
											options={roleOpts}
											valueOfSelected={role}
										/>
									</EuiFormRow>
									{role === 'pharmacist' && (
										<EuiFormRow
											error={
												touched.s2License &&
												errors.s2License
											}
											isInvalid={
												touched.s2License &&
												!!errors.s2License
											}
											label="License Number"
										>
											<EuiFieldText
												data-testid="s2License-input"
												id="s2License"
												isInvalid={
													touched.s2License &&
													!!errors.s2License
												}
												name="s2License"
												onChange={handleChange}
												placeholder="License Number"
												value={s2License}
											/>
										</EuiFormRow>
									)}
								</EuiFlexItem>
							</EuiFlexGroup>
						</EuiFlexItem>
					</EuiFlexItem>
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={() => {
								onClose();
								resetForm({ values: initialValues });
							}}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="checkInCircleFilled"
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

AddUser.propTypes = {
	addUser: PropTypes.func.isRequired,
	isLoading: PropTypes.bool.isRequired,
	onClose: PropTypes.bool.isRequired,
	setError: PropTypes.string.isRequired,
	visible: PropTypes.bool.isRequired,
};

export default AddUser;
