import React, { useCallback, useState } from 'react';

import { EuiComboBox } from '@elastic/eui';

import PropTypes from 'prop-types';

import { getPatients } from 'modules/patients/patients.fetch';

const PatientSearch = ({ onChange, ...props }) => {
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);
	const onSelect = (e) => {
		const [selectedOption] = e;

		if (!selectedOption) {
			setSelected([]);

			return onChange(null);
		}

		onChange(selectedOption);

		return setSelected(e);
	};

	const onSearchChange = useCallback(async (searchValue) => {
		if (!searchValue.length || searchValue.length < 3) return;

		const { data: results } = await getPatients({
			_q: searchValue,
		});

		const processedOptions = results.map((res) => ({
			label: `${res.lastName}, ${res.firstName}`,
			value: res.id,
			...res,
		}));

		setOptions(processedOptions);
	});

	return (
		<EuiComboBox
			async
			onChange={onSelect}
			onSearchChange={onSearchChange}
			options={options}
			placeholder="Search Patient"
			selectedOptions={selected}
			singleSelection={{ asPlainText: true }}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

PatientSearch.propTypes = {
	onChange: PropTypes.func.isRequired,
};

export default PatientSearch;
