/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Document, Page as DocumentPage, pdfjs } from 'react-pdf';

import {
	EuiTitle,
	// EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	// EuiSuperSelect,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiTextArea,
	EuiFieldText,
	EuiFieldNumber,
	EuiDescriptionList,
	// EuiDatePicker,
	EuiSpacer,
	EuiImage,
	EuiFlexGrid,
	EuiToolTip,
	EuiIcon,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import _ from 'lodash';

import ContactDetails from 'modules/_global/ContactDetails';
import DoctorQuickView from 'modules/_global/DoctorQuickView';
import ProductSearch from 'modules/_global/ProductSearch';
import Page from 'components/Page';
import Print from 'components/Print';
import Table from 'components/Table';
import ConfirmationModal from 'components/ConfirmationModal';
import BranchDropdown from 'components/forms/BranchDropdown';

import RxStatus from './components/RxStatus';
import CreateOrderButton from './components/CreateOrderButton';
import { addToast } from '../dashboard/dashboard.actions';
import {
	getPrescription,
	readPrescription,
	updatePrescription,
} from './prescriptions.fetch';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ItemFormSchema = Yup.object().shape({
	generic: Yup.string().required('Generic Product is required'),
	brand: Yup.string().required('Brand Name is required'),
	quantity: Yup.number()
		.min(1, 'Minimum quantity is 1')
		.default(0)
		.required('Quantity is required'),
});

const Prescription = () => {
	const { t } = useTranslation();
	const { prescriptionId } = useParams();
	const dispatch = useDispatch();
	const { vendor } = useSelector((s) => ({
		vendor: _.get(s, 'auth.user.vendor.id'),
	}));
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [prescription, setPrescription] = useState(null);
	const [hasChanges, setHasChanges] = useState(false);
	const [selectedItem, setSelectedItem] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [numPages, setNumPages] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [pageNumber, setPageNumber] = useState(1);
	const [
		isConfirmDeleteModalVisisble,
		setIsConfirmDeleteModalVisible,
	] = useState(false);
	const [
		isConfirmCancelChangesModalVisible,
		setIsConfirmCancelChangesModalVisible,
	] = useState(false);
	const searchInput = useRef(null);

	useEffect(() => {
		async function getPrescriptionRecord() {
			try {
				const { data } = await getPrescription(prescriptionId);
				setPrescription(data);
				readPrescription(data.id);
			} catch (err) {
				setError(err.message || err || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}

		getPrescriptionRecord();
	}, [prescriptionId, isLoading]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	const {
		handleSubmit,
		initialValues,
		resetForm,
		setFieldValue,
		values,
	} = useFormik({
		initialValues: {
			code: prescription && prescription.code,
			doctor: prescription ? prescription.doctor : {},
			patient: prescription ? prescription.patient : {},
			clinic: prescription ? prescription.clinic : {},
			createdAt: prescription && prescription.createAt,
			type: prescription && prescription.type,
			prescriptionItems: prescription
				? prescription.prescriptionItems
				: [],
			status: prescription && prescription.status,
			attachments: prescription ? prescription.attachments : [],
			order: prescription ? prescription.order : [],
			branch: prescription ? prescription.branch : null,
			pickup: prescription ? prescription.pickup : false,
		},
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			try {
				setIsLoading(true);
				const payload = {
					prescriptionItems: data.prescriptionItems.map((item) => ({
						...item,
						product: _.get(item, 'product.id', null),
					})),
				};

				if (data.branch && typeof data.branch === 'string') {
					payload.branch = data.branch;
				}

				const result = await updatePrescription(
					prescriptionId,
					payload,
				);

				setPrescription(result.data);
				dispatch(
					addToast(
						'Success',
						'Prescription updated',
						'success',
						'check',
					),
				);
			} catch (err) {
				setError(err.message || 'Something went wrong');
				resetForm({ values: initialValues });
			} finally {
				setError(null);
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	const {
		attachments,
		code,
		doctor,
		patient,
		prescriptionItems,
		status,
	} = values;

	const isServed = status === 'served';
	const isNew = status === 'new';
	const isEncoded = status === 'encoded';

	const itemForm = useFormik({
		initialValues: {
			mode: 'Add',
			id: '',
			generic: '',
			brand: '',
			formulation: '',
			sig: '',
			quantity: 0,
			product: null,
		},
		validationSchema: ItemFormSchema,
		validateOnBlur: true,
		onSubmit: async (data) => {
			const newItem = {
				generic: data.generic,
				brand: data.brand,
				formulation: data.formulation,
				sig: data.sig,
				quantity: data.quantity,
				product: data.product,
			};

			if (data.mode === 'Add') {
				const updatedPrescriptionItems = [
					...prescriptionItems,
					newItem,
				];

				setFieldValue('prescriptionItems', updatedPrescriptionItems);
			} else {
				setFieldValue(
					'prescriptionItems',
					prescriptionItems.map((item) => {
						if (item.id === data.id) {
							return {
								...item,
								...newItem,
								currentQty: newItem.quantity,
							};
						}

						return item;
					}),
				);
				searchInput.current.clear();
			}

			itemForm.resetForm({ values: itemForm.initialValues });
			searchInput.current.clear();
		},
	});

	const {
		brand,
		formulation,
		generic,
		mode,
		quantity,
		sig,
	} = itemForm.values;

	const handleEdit = (data) => {
		itemForm.setFieldValue('mode', 'Edit');
		itemForm.setFieldValue('id', data.id);
		itemForm.setFieldValue('generic', data.generic);
		itemForm.setFieldValue('brand', data.brand);
		itemForm.setFieldValue('formulation', data.formulation);
		itemForm.setFieldValue('quantity', data.quantity);
		itemForm.setFieldValue('sig', data.sig);

		if (data.product) {
			searchInput.current.setValue({
				...data.product,
				label: data.product.name,
			});
		}
	};

	// eslint-disable-next-line no-unused-vars
	const removePrescriptionItem = (data) => {
		// eslint-disable-next-line no-unused-vars
		const index = prescriptionItems.findIndex(
			(item) => item.id === data.id,
		);
		const prescriptionItemsStart = prescriptionItems.slice(0, index);
		const prescriptionItemsEnd = prescriptionItems.slice(index + 1);
		setFieldValue('prescriptionItems', [
			...prescriptionItemsStart,
			...prescriptionItemsEnd,
		]);
	};

	// eslint-disable-next-line no-unused-vars
	const handleDelete = (data) => {
		setSelectedItem(data);
		setIsConfirmDeleteModalVisible(true);
	};

	// eslint-disable-next-line no-unused-vars
	const updateStatus = async (data, value) => {
		try {
			// updatePrescriptionStatus(prescriptionId, value);
			dispatch(
				addToast(
					'Success',
					'Prescription completed',
					'success',
					'check',
				),
			);
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
		}
	};

	// const handleCancel = () => {
	// 	resetForm({ values: initialValues });
	// };

	const onDocumentLoadSuccess = ({ pages }) => {
		setNumPages(pages);
	};

	const confirmDeleteModal = isConfirmDeleteModalVisisble ? (
		<ConfirmationModal
			message="Are you sure you want to do this?"
			onClose={() => {
				setIsConfirmDeleteModalVisible(false);
				setSelectedItem(null);
			}}
			onConfirm={() => {
				itemForm.resetForm({ values: itemForm.initialValues });
				removePrescriptionItem(selectedItem);
				setIsConfirmDeleteModalVisible(false);
			}}
			title="Delete Line Item"
		/>
	) : null;

	const confirmCancelChangesModal = isConfirmCancelChangesModalVisible ? (
		<ConfirmationModal
			message="Cancel Changes?"
			onClose={() => {
				setIsConfirmCancelChangesModalVisible(false);
			}}
			onConfirm={() => {
				resetForm({ values: initialValues });
				itemForm.resetForm({ values: itemForm.initialValues });
				setIsConfirmCancelChangesModalVisible(false);
			}}
			title="Confirm"
		/>
	) : null;

	let columns = [
		{
			field: 'generic',
			name: t('prescriptions.generic'),
			sortable: true,
		},
		{
			field: 'brand',
			name: t('prescriptions.brand'),
			sortable: true,
		},
		{
			field: 'formulation',
			name: t('prescriptions.formulation'),
			sortable: true,
		},
		{
			field: 'currentQty',
			name: (
				<span>
					{t('prescriptions.quantity')}{' '}
					<EuiToolTip
						content="Remaining Quantity available for the next order: Available of Prescribed"
						delay="long"
					>
						<EuiIcon
							className="eui-alignCenter"
							color="subdued"
							size="s"
							type="questionInCircle"
						/>
					</EuiToolTip>
				</span>
			),
			sortable: true,
			dataType: 'number',
			render: (currentQty, { quantity: qty }) => {
				return `${
					typeof currentQty !== 'undefined' ? `${currentQty} of ` : ''
				}${qty}`;
			},
			width: '13%',
		},
	];

	if (isNew || isEncoded) {
		columns = [
			...columns,
			{
				width: '10%',
				actions: [
					{
						name: 'Edit',
						description: 'Edit',
						onClick: handleEdit,
						icon: 'pencil',
						type: 'icon',
						color: 'primary',
						isPrimary: true,
					},
					{
						name: 'Delete',
						description: 'Delete',
						onClick: handleDelete,
						icon: 'trash',
						type: 'icon',
						color: 'danger',
						isPrimary: true,
					},
				],
			},
		];
	}

	const onSelect = useCallback((value) => {
		itemForm.setFieldValue('generic', value.generic);
		itemForm.setFieldValue(
			'brand',
			_.get(value, 'brand.name') || _.get(value, 'name') || 'Generic',
		);
		itemForm.setFieldValue('formulation', value.formulation);
		itemForm.setFieldValue('product', value);
	});

	if (!prescription) {
		return null;
	}

	const pdfAttachment =
		attachments.length > 0 ? attachments[0].mime === 'application/pdf' : '';
	const imageAttachment =
		attachments.length > 0
			? attachments[0].mime === 'image/jpeg' ||
			  attachments[0].mime === 'image/png'
			: '';

	const onBranchSelect = (selectedOption) => {
		if (selectedOption) {
			return setFieldValue('branch', selectedOption.value);
		}

		return setFieldValue('branch', null);
	};

	return (
		<Page
			headerRight={
				<EuiFlexGroup direction="row" gutterSize="l">
					{!isServed && (
						<EuiFlexItem>
							<EuiButtonEmpty
								color="danger"
								disabled={isLoading || hasChanges}
								iconType="crossInACircleFilled"
								onClick={() =>
									setIsConfirmCancelChangesModalVisible(true)
								}
							>
								{t('general.cancel')}
							</EuiButtonEmpty>
						</EuiFlexItem>
					)}
					<EuiFlexItem>
						<Print>
							{imageAttachment && (
								<EuiFlexItem style={{ alignItems: 'center' }}>
									<EuiImage
										allowFullScreen
										alt={`${process.env.REACT_APP_API_URL}${attachments[0].name}`}
										hasShadow
										url={`${process.env.REACT_APP_API_URL}${attachments[0].url}`}
									/>
								</EuiFlexItem>
							)}
							{pdfAttachment && (
								<Document
									externalLinkTarget
									file={`${process.env.REACT_APP_API_URL}${attachments[0].url}`}
									onLoadSuccess={onDocumentLoadSuccess}
								>
									<DocumentPage
										pageNumber={pageNumber}
										width="500"
									/>
								</Document>
							)}
						</Print>
					</EuiFlexItem>
					{!isServed && (
						<>
							<CreateOrderButton
								disabled={
									isNew ||
									isServed ||
									!hasChanges ||
									!values.branch
								}
								prescription={prescription}
							/>
							<EuiFlexItem>
								<EuiButton
									disabled={isLoading || hasChanges}
									fill
									iconType="checkInCircleFilled"
									isLoading={isLoading}
									onClick={handleSubmit}
								>
									{t('general.saveChanges')}
								</EuiButton>
							</EuiFlexItem>
						</>
					)}
				</EuiFlexGroup>
			}
			largeSidebar
			sidebar={
				<div>
					<EuiFlexItem>
						<EuiDescriptionList
							listItems={[
								{
									title: 'Doctor',
									description: doctor ? (
										<DoctorQuickView doctorId={doctor.id} />
									) : null,
								},
								{
									title: 'Patient',
									description: patient
										? `${patient.firstName} ${patient.lastName}`
										: null,
								},
								{
									title: 'Contact Details',
									description: patient ? (
										<ContactDetails
											ids={patient.contactDetails}
										/>
									) : null,
								},
								{
									title: 'Status',
									description: <RxStatus status={status} />,
								},
								{
									title: 'Branch',
									description:
										values.branch && values.branch.name ? (
											values.branch.name
										) : (
											<BranchDropdown
												onChange={onBranchSelect}
												vendor={vendor}
											/>
										),
								},
							]}
							style={{ maxWidth: '500px' }}
							type="column"
						/>
					</EuiFlexItem>
					<EuiSpacer size="xl" />
					{/* handling attachements for now needs to be improve for multiple file handling */}
					{pdfAttachment && (
						<Document
							externalLinkTarget
							file={`${process.env.REACT_APP_API_URL}${attachments[0].url}`}
							onLoadSuccess={onDocumentLoadSuccess}
						>
							<DocumentPage pageNumber={pageNumber} width="500" />
						</Document>
					)}

					{imageAttachment && (
						<EuiFlexItem style={{ alignItems: 'center' }}>
							<EuiImage
								allowFullScreen
								alt={`${process.env.REACT_APP_API_URL}${attachments[0].name}`}
								// caption="Small"
								hasShadow
								url={`${process.env.REACT_APP_API_URL}${attachments[0].url}`}
							/>
						</EuiFlexItem>
					)}
				</div>
			}
			stickyHeader
			title={t('prescriptions.code', {
				code,
			})}
		>
			{!isServed && (
				<>
					<EuiTitle size="xs">
						<h3>{`${mode} Item`}</h3>
					</EuiTitle>
					<EuiHorizontalRule margin="xs" />
					<EuiForm>
						<EuiFlexGroup>
							<EuiFlexItem style={{ width: '100%' }}>
								<EuiFormRow label="Search Product">
									<ProductSearch
										ref={searchInput}
										onSelect={onSelect}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
						<EuiFlexGroup>
							<EuiFlexItem style={{ flex: 2 }}>
								<EuiFlexGrid columns={2}>
									<EuiFlexItem>
										<EuiFormRow
											error={
												itemForm.touched.generic &&
												itemForm.errors.generic
											}
											isInvalid={
												itemForm.touched.generic &&
												!!itemForm.errors.generic
											}
											label="Generic Name"
										>
											<EuiFieldText
												data-testid="generic-input"
												id="generic"
												isInvalid={
													itemForm.touched.generic &&
													!!itemForm.errors.generic
												}
												name="generic"
												onChange={itemForm.handleChange}
												placeholder="Generic Product"
												value={generic}
											/>
										</EuiFormRow>
									</EuiFlexItem>
									<EuiFlexItem>
										<EuiFormRow
											error={
												itemForm.touched.brand &&
												itemForm.errors.brand
											}
											isInvalid={
												itemForm.touched.brand &&
												!!itemForm.errors.brand
											}
											label="Brand"
										>
											<EuiFieldText
												data-testid="brand-input"
												id="brand"
												isInvalid={
													itemForm.touched.brand &&
													!!itemForm.errors.brand
												}
												name="brand"
												onChange={itemForm.handleChange}
												placeholder="Brand"
												value={brand}
											/>
										</EuiFormRow>
									</EuiFlexItem>

									<EuiFlexItem>
										<EuiFormRow
											error={
												itemForm.touched.formulation &&
												itemForm.errors.formulation
											}
											isInvalid={
												itemForm.touched.formulation &&
												!!itemForm.errors.formulation
											}
											label="Formulation"
										>
											<EuiFieldText
												data-testid="formulation-input"
												id="formulation"
												isInvalid={
													itemForm.touched
														.formulation &&
													!!itemForm.errors
														.formulation
												}
												name="formulation"
												onChange={itemForm.handleChange}
												placeholder="Fomulation"
												value={formulation}
											/>
										</EuiFormRow>
									</EuiFlexItem>
									<EuiFlexItem>
										<EuiFormRow
											error={
												itemForm.touched.quantity &&
												itemForm.errors.quantity
											}
											helpText={
												mode === 'Edit'
													? 'Updating quantity will reset the current quantity'
													: ''
											}
											isInvalid={
												itemForm.touched.quantity &&
												!!itemForm.errors.quantity
											}
											label="Quantity"
										>
											<EuiFieldNumber
												data-testid="quantity-input"
												id="quantity"
												isInvalid={
													itemForm.touched.quantity &&
													!!itemForm.errors.quantity
												}
												name="quantity"
												onChange={itemForm.handleChange}
												placeholder="Quantity"
												value={quantity}
											/>
										</EuiFormRow>
									</EuiFlexItem>
								</EuiFlexGrid>
							</EuiFlexItem>
							<EuiFlexItem style={{ flex: 1 }}>
								<EuiFormRow
									// error={touched.sig && errors.sig}
									// isInvalid={touched.sig && !!errors.sig}
									label="SIG"
								>
									<EuiTextArea
										data-testid="sig-input"
										disabled
										id="sig"
										name="sig"
										// isInvalid={touched.sig && !!errors.sig}
										onChange={itemForm.handleChange}
										placeholder="SIG"
										style={{ height: '120px' }}
										value={sig}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
						<EuiFlexGroup>
							<EuiFlexItem
								style={{ flex: 1, alignItems: 'flex-end' }}
							>
								<EuiFormRow>
									<EuiFlexGroup
										gutterSize="s"
										justifyContent="flexEnd"
									>
										<EuiFlexItem>
											<EuiButton
												color="danger"
												fullWidth={false}
												iconType="broom"
												onClick={() => {
													itemForm.resetForm({
														values:
															itemForm.initialValues,
													});
												}}
												size="s"
											>
												Clear
											</EuiButton>
										</EuiFlexItem>
										<EuiFlexItem>
											<EuiButton
												fullWidth={false}
												iconType="plusInCircle"
												onClick={itemForm.handleSubmit}
												size="s"
											>
												{`${
													mode === 'Add'
														? 'Add Item'
														: 'Update Item'
												}`}
											</EuiButton>
										</EuiFlexItem>
									</EuiFlexGroup>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiForm>
					<EuiSpacer size="l" />
					<EuiHorizontalRule margin="s" />
				</>
			)}
			<EuiSpacer size="l" />
			<EuiTitle size="xs">
				<h3>Prescription Items</h3>
			</EuiTitle>
			<EuiHorizontalRule margin="s" />
			<Table
				columns={columns}
				isExpandable
				itemId="id"
				items={prescriptionItems}
				// selection={selection}
			/>
			{confirmCancelChangesModal}
			{confirmDeleteModal}
		</Page>
	);
};

export default Prescription;
