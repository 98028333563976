import React from 'react';
import { Helmet } from 'react-helmet-async';

import { useTranslation } from 'react-i18next';

import { EuiFlexGroup } from '@elastic/eui';

import Page from 'components/Page';
import AddRxButton from 'modules/prescriptions/components/AddRxButton';
import Statistic from 'modules/_global/Statistics';
import DoctorDashboardContent from './DoctorDashboardContent';

export default function Dashboard() {
	const { t } = useTranslation();

	return (
		<>
			<Helmet title="Home" />
			<Page
				headerRight={
					<EuiFlexGroup direction="row" gutterSize="m">
						<AddRxButton dashboard />
					</EuiFlexGroup>
				}
				title={t('dashboard.title')}
			>
				<DoctorDashboardContent />
				<Statistic />
			</Page>
		</>
	);
}
