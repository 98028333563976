import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FiMenu } from 'react-icons/fi';

import {
	EuiButtonIcon,
	EuiCollapsibleNav,
	EuiCollapsibleNavGroup,
	EuiHeader,
	EuiHeaderSection,
	EuiHeaderSectionItem,
	EuiShowFor,
	EuiListGroupItem,
} from '@elastic/eui';
import _ from 'lodash';

// import Logo from 'assets/images/NowNaLogoWithText.png';
import { ROLE_PATH } from 'constants/protect';
import { DOCTOR } from 'components/roles';
import { menu } from 'components/menu';

import { dockAction, undockAction } from 'modules/dashboard/dashboard.actions';
import Toasts from 'modules/dashboard/Toasts';
import ValidationModal from 'modules/dashboard/ValidationModal';
import HeaderUserMenu from 'components/HeaderUserMenu';

function DashboardLayout() {
	const { t } = useTranslation();
	const { auth, dashboard, role } = useSelector((state) => ({
		dashboard: state.dashboard,
		auth: state.auth,
		role: _.get(state, ROLE_PATH),
	}));
	const dispatch = useDispatch();
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleDock = () => {
		if (dashboard.isNavDocked) return dispatch(undockAction());
		return dispatch(dockAction());
	};

	/**
	 * Adding routes based on roles.
	 */
	const routes = menu(t).reduce((acc, list) => {
		if (list.users.includes(role)) return acc.concat(list);
		return acc;
	}, []);

	if (!auth.isLoggedIn) {
		return <Redirect to="/login" />;
	}

	const menuButton = (
		<EuiButtonIcon
			aria-label="Toggle main navigation"
			className="main-menu-button"
			color="primary"
			data-testid="main-menu-button"
			iconType={() => <FiMenu color="#fff" size={22} />}
			onClick={() => setMenuOpen(!menuOpen)}
		/>
	);

	return (
		<>
			<EuiHeader position="fixed" theme="dark">
				<EuiHeaderSection>
					<EuiHeaderSectionItem>
						<EuiCollapsibleNav
							button={menuButton}
							isDocked={dashboard.isNavDocked}
							isOpen={menuOpen}
							onClose={() => setMenuOpen(false)}
							showCloseButton
						>
							{routes
								.filter(({ inMenu = true }) => inMenu)
								.map((route) => (
									<NavLink
										key={`main-menu-${route.name}`}
										onClick={() => setMenuOpen(false)}
										to={route.path}
									>
										<EuiCollapsibleNavGroup
											iconType={route.icon}
											initialIsOpen={false}
											isCollapsible={false}
											title={route.title}
											titleSize="xs"
										/>
									</NavLink>
								))}

							<EuiShowFor sizes={['l', 'xl']}>
								<EuiCollapsibleNavGroup>
									<EuiListGroupItem
										color="subdued"
										iconType={
											dashboard.isNavDocked
												? 'lock'
												: 'lockOpen'
										}
										label={`${
											dashboard.isNavDocked
												? 'Undock'
												: 'Dock'
										} navigation`}
										onClick={toggleDock}
										size="xs"
									/>
								</EuiCollapsibleNavGroup>
							</EuiShowFor>
						</EuiCollapsibleNav>
					</EuiHeaderSectionItem>

					<EuiHeaderSectionItem className="brand-container">
						<NavLink to="/">
							{/* <EuiImage alt="Go Back to Home Page" url={Logo} /> */}
						</NavLink>
					</EuiHeaderSectionItem>
				</EuiHeaderSection>

				<EuiHeaderSection side="right">
					<EuiHeaderSectionItem>
						<HeaderUserMenu />
					</EuiHeaderSectionItem>
				</EuiHeaderSection>
			</EuiHeader>
			<Switch>
				{routes.map((route) => (
					// eslint-disable-next-line react/jsx-props-no-spreading
					<Route key={`dashboard-${route.name}`} {...route} />
				))}
			</Switch>
			<Toasts />
			<ValidationModal
				pending={_.get(auth, 'user.details.validationPending')}
				visible={
					role === DOCTOR.key &&
					!_.get(auth, 'user.details.prcValidated')
				}
			/>
		</>
	);
}

DashboardLayout.propTypes = {};

DashboardLayout.defaultProps = {};

export default DashboardLayout;
