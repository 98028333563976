import React, { useEffect, useState } from 'react';

import { EuiSuperSelect } from '@elastic/eui';

import PropTypes from 'prop-types';

import DropdownOptWSub from 'components/DropdownOptWSub';
import {
	getVendors,
	getVendorsByAddress,
	getVendorsByCity,
} from 'modules/vendors/vendors.fetch';

const VendorDropdown = ({
	address,
	cityId,
	onChange,
	preferred,
	selected,
	...props
}) => {
	const [vendors, setVendors] = useState([]);

	useEffect(() => {
		const constructName = (data, pref) => {
			let text = data.name;

			if (pref === data.id) {
				text += ' (Preferred)';
			}

			return text;
		};

		const mapOpts = (data) => (v) => {
			const found = data.find((d) => d.id === v.id);
			const name = constructName(v, preferred);
			return {
				...v,
				inputDisplay: name,
				dropdownDisplay: (
					<DropdownOptWSub
						description={
							!found
								? 'No Branches Available for Address'
								: 'Branches Available'
						}
						title={name}
					/>
				),
				disabled: !found,
			};
		};

		const fetchVendorsByCity = async (opts) => {
			const { data } = await getVendorsByCity({ id: cityId });

			return opts.map(mapOpts(data));
		};

		const fetchVendorsByGeo = async (opts) => {
			const { data } = await getVendorsByAddress({ address });
			return opts.map(mapOpts(data));
		};

		const fetchVendors = async () => {
			const { data } = await getVendors();

			let opts = data.map((d) => ({
				...d,
				inputDisplay: constructName(d, preferred),
				dropdownDisplay: (
					<DropdownOptWSub
						description="Branches Available"
						title={constructName(d, preferred)}
					/>
				),
				value: d.id,
				disabled: false,
			}));

			if (cityId) {
				opts = await fetchVendorsByCity(opts);
			}

			if (address) {
				opts = await fetchVendorsByGeo(opts);
			}

			const current = opts.find((opt) => opt.id === selected);

			if (current && current.disabled) {
				onChange(null);
			}

			if (current && !current.disabled) {
				onChange(current.id);
			}

			setVendors(opts);
		};

		fetchVendors();
	}, [cityId, address]);

	return (
		<EuiSuperSelect
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
			onChange={onChange}
			options={vendors}
			valueOfSelected={selected}
		/>
	);
};

VendorDropdown.propTypes = {
	address: PropTypes.string,
	cityId: PropTypes.string,
	onChange: PropTypes.func.isRequired,
	preferred: PropTypes.string,
	selected: PropTypes.string,
};

VendorDropdown.defaultProps = {
	address: null,
	cityId: null,
	preferred: null,
	selected: null,
};

export default VendorDropdown;
