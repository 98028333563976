import React from 'react';
import {
	EuiOverlayMask,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiModalFooter,
	EuiButton,
} from '@elastic/eui';

import PropTypes from 'prop-types';
import TermsAndConditions from 'modules/_global/TermsAndConditions';

const TermsModal = ({ isOpen, onClose }) => {
	if (!isOpen) return null;

	return (
		<EuiOverlayMask onClick={onClose}>
			<EuiModal onClose={onClose}>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						Terms and Conditions
					</EuiModalHeaderTitle>
				</EuiModalHeader>

				<EuiModalBody>
					<TermsAndConditions />
				</EuiModalBody>

				<EuiModalFooter>
					<EuiButton fill onClick={onClose}>
						Close
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

TermsModal.propTypes = {
	isOpen: PropTypes.bool,
	onClose: PropTypes.func.isRequired,
};

TermsModal.defaultProps = {
	isOpen: false,
};

export default TermsModal;
