import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
	EuiImage,
	EuiPage,
	EuiPageBody,
	EuiPageContent,
	EuiPageContentBody,
	EuiPageContentHeader,
	EuiPageContentHeaderSection,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import Logo from 'assets/images/logo_small.png';

function AuthLayout({ children, containerStyle }) {
	const auth = useSelector((state) => state.auth);

	// redirect to dashboard if already logged in
	if (auth.isLoggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<EuiPage data-testid="auth-layout-container" style={containerStyle}>
			<EuiPageBody component="div">
				<EuiPageContent
					className="auth form-container"
					horizontalPosition="center"
					verticalPosition="center"
				>
					<EuiPageContentHeader>
						<EuiPageContentHeaderSection>
							<EuiImage alt="ERx Dashboard" size="m" url={Logo} />
						</EuiPageContentHeaderSection>
					</EuiPageContentHeader>
					<EuiPageContentBody>{children}</EuiPageContentBody>
				</EuiPageContent>
			</EuiPageBody>
		</EuiPage>
	);
}

AuthLayout.propTypes = {
	children: PropTypes.node.isRequired,
	containerStyle: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object),
	]),
};

AuthLayout.defaultProps = {
	containerStyle: null,
};

export default AuthLayout;
