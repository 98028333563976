// Auth Types
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const UPDATE_USER_DETAILS = 'UPDATE_USER_DETAILS';

// App Settings
export const APP_DOCK_NAVIGATION = 'APP_DOCK_NAVIGATION';
export const APP_UNDOCK_NAVIGATION = 'APP_UNDOCK_NAVIGATION';

// Toasts
export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const CLEAR_TOASTS = 'CLEAR_TOASTS';

// Prescriptions
export const CREATE_RX_SUCCESS = 'CREATE_RX_SUCCESS';
export const READ_RX_SUCCESS = 'READ_RX_SUCCESS';
export const UPDATE_RX_SUCCESS = 'UPDATE_RX_SUCCESS';
export const DELETE_RX_SUCCESS = 'DELETE_RX_SUCCESS';
export const FIND_RX_SUCCESS = 'FIND_RX_SUCCESS';

// Dashboard
export const GET_LATEST_RX_SUCCESS = 'GET_LATEST_RX_SUCCESS';
export const CREATE_RX_DASHBOARD_SUCCESS = 'CREATE_RX_DASHBOARD_SUCCESS';
