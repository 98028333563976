import React, { useState, useEffect } from 'react';

import {
	EuiStat,
	EuiPanel,
	EuiFlexGroup,
	EuiFlexItem,
	EuiTitle,
	EuiHorizontalRule,
	EuiButtonIcon,
	EuiButtonEmpty,
} from '@elastic/eui';

import { ROLE_PATH } from 'constants/protect';
import { ADMIN, PHARMACIST, SUPER_ADMIN } from 'components/roles';
import protectHOC from 'components/protectHOC';
import api from 'utils/api';

const Statistics = () => {
	const [isLoading, setIsLoading] = useState(false);
	const [values, setValues] = useState({
		newRx: 0,
		encodedRx: 0,
		newOrders: 0,
		confirmedOrders: 0,
		completedOrders: 0,
		totalRx: 0,
		totalOrders: 0,
	});

	const loadReports = async () => {
		if (isLoading) {
			return;
		}

		setIsLoading(true);
		const [
			{ data: newRx },
			{ data: encodedRx },
			{ data: newOrders },
			{ data: confirmedOrders },
			{ data: completedOrders },
			{ data: totalRx },
			{ data: totalOrders },
		] = await Promise.all([
			api.get('/prescriptions/count?status=new'),
			api.get('/prescriptions/count?status=encoded'),
			api.get('/orders/count?status=open'),
			api.get('/orders/count?status=confirmed'),
			api.get('/orders/count?status=completed'),
			api.get('/prescriptions/count'),
			api.get('/orders/count'),
		]);

		setValues({
			newRx,
			encodedRx,
			newOrders,
			confirmedOrders,
			completedOrders,
			totalRx,
			totalOrders,
		});

		setIsLoading(false);
	};

	useEffect(() => {
		loadReports();
	}, []);

	return (
		<div>
			<EuiTitle>
				<h1>
					Summary{' '}
					{isLoading ? (
						<EuiButtonEmpty isLoading size="xs" />
					) : (
						<EuiButtonIcon
							aria-label="refresh"
							color="subdued"
							iconType="refresh"
							onClick={loadReports}
						/>
					)}
				</h1>
			</EuiTitle>

			<EuiHorizontalRule margin="s" />
			<EuiFlexGroup>
				<EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="New Prescriptions"
							isLoading={isLoading}
							textAlign="center"
							title={values.newRx}
						/>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="Encoded Prescriptions"
							isLoading={isLoading}
							textAlign="center"
							title={values.encodedRx}
						/>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="New Orders"
							isLoading={isLoading}
							textAlign="center"
							title={values.newOrders}
						/>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="Confirmed Orders"
							isLoading={isLoading}
							textAlign="center"
							title={values.confirmedOrders}
						/>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="Completed Orders"
							isLoading={isLoading}
							textAlign="center"
							title={values.completedOrders}
						/>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="Total Prescriptions"
							isLoading={isLoading}
							textAlign="center"
							title={values.totalRx}
						/>
					</EuiPanel>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiPanel>
						<EuiStat
							description="Total Orders"
							isLoading={isLoading}
							textAlign="center"
							title={values.totalOrders}
						/>
					</EuiPanel>
				</EuiFlexItem>
			</EuiFlexGroup>
		</div>
	);
};

export default protectHOC(
	[SUPER_ADMIN.key, ADMIN.key, PHARMACIST.key],
	ROLE_PATH,
)(Statistics);
