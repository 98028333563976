import api from 'utils/api';

// export const createPatient = async (payload) => api.post('/patients', payload);

export const getPatients = async (params) => {
	return api.get('/patients', {
		params,
	});
};

export const getPatient = async (id) => api.get(`/patients/${id}`);

export const updatePatient = async (id, payload) =>
	api.put(`/patients/${id}`, payload);
