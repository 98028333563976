import React from 'react';

import { EuiGlobalToastList } from '@elastic/eui';
import { useDispatch, useSelector } from 'react-redux';
import { removeToast } from './dashboard.actions';

export default () => {
	const dispatch = useDispatch();
	const { toasts } = useSelector((state) => state.dashboard);

	const dismissToast = (toast) => {
		dispatch(removeToast(toast));
	};

	return (
		<EuiGlobalToastList
			dismissToast={dismissToast}
			toastLifeTimeMs={3000}
			toasts={toasts}
		/>
	);
};
