import React, { useState } from 'react';
import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiFlyoutBody,
	EuiFlyoutFooter,
	EuiFlexGroup,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiButton,
	EuiText,
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiFlexGrid,
	EuiSpacer,
	EuiFilePicker,
	EuiImage,
} from '@elastic/eui';

import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';

// eslint-disable-next-line react/prop-types
const AddUser = ({ addUser, onClose, setError, visible }) => {
	const { t } = useTranslation();
	const [isLoading, setIsLoading] = useState(false);
	const [files, setFiles] = useState({});

	const {
		// errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		// setFieldValue,
		values,
	} = useFormik({
		initialValues: {
			productId: 'ABCD12345',
			firstName: '',
			middleName: '',
			lastName: '',
			addressLine1: '',
			addressLine2: '',
			prcLicense: '',
			quantity: 0,
			role: 'none',
		},
		validateOnBlur: true,
		onSubmit: async (data) => {
			try {
				addUser(data);
			} catch (err) {
				// set error message from api request failure
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
				resetForm({ values: initialValues });
			}
		},
	});

	const {
		addressLine1,
		addressLine2,
		firstName,
		lastName,
		middleName,
	} = values;

	const renderFiles = () => {
		if (files.length > 0) {
			return (
				<ul>
					{Object.keys(files).map((item) => (
						<li key={files[item].name}>
							<strong>{files[item].name}</strong> (
							{files[item].size} bytes)
						</li>
					))}
				</ul>
			);
		}
		return (
			<EuiImage
				allowFullScreen={false}
				alt="Accessible image alt goes here"
				// caption="Medium"
				hasShadow
				size="m"
				url="https://source.unsplash.com/1000x1000/?Nature"
			/>
		);
	};

	if (!visible) return null;

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Order"
			onClose={() => {
				onClose();
				resetForm({ values: initialValues });
			}}
			size="m"
			// TODO: move to stylesheet
			style={{ zIndex: 0 }}
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add Customer</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFlexItem grow={false}>
						<EuiFlexGrid columns={2}>
							<EuiFlexItem>
								<EuiFlexGroup>
									<EuiFlexItem>
										<EuiText>
											<h3>Customer Information</h3>
										</EuiText>
										<EuiSpacer />
										<EuiFormRow label="Customer's Photo">
											<EuiFilePicker
												aria-label="Use aria labels when no actual label is in use"
												display="medium"
												id="asdf2"
												initialPromptText="Select or drag and drop photo here"
												multiple
												onChange={setFiles}
											/>
										</EuiFormRow>
										<EuiSpacer />
										<EuiFormRow
											// error={errors.firstName}
											// isInvalid={!!errors.firstName}
											label="First Name"
										>
											<EuiFieldText
												data-testid="firstName-input"
												// icon="user"
												id="firstName"
												// isInvalid={!!errors.firstName}
												name="firstName"
												onChange={handleChange}
												placeholder="First Name"
												value={firstName}
											/>
										</EuiFormRow>
										<EuiFormRow
											// error={errors.middleName}
											// isInvalid={!!errors.middleName}
											label="Middle Name"
										>
											<EuiFieldText
												data-testid="middleName-input"
												// icon="user"
												id="middleName"
												// isInvalid={!!errors.middleName}
												name="middleName"
												onChange={handleChange}
												placeholder="Middle Name"
												value={middleName}
											/>
										</EuiFormRow>
										<EuiFormRow
											// error={errors.lastName}
											// isInvalid={!!errors.lastName}
											label="Last Name"
										>
											<EuiFieldText
												data-testid="lastName-input"
												// icon="user"
												id="lastName"
												// isInvalid={!!errors.lastName}
												name="lastName"
												onChange={handleChange}
												placeholder="Last Name"
												value={lastName}
											/>
										</EuiFormRow>
										<EuiSpacer size="l" />
										<EuiFormRow
											// error={errors.addressLine1}
											// isInvalid={!!errors.addressLine1}
											label="Address Line 1"
										>
											<EuiFieldText
												data-testid="addressLine1-input"
												id="addressLine1"
												// isInvalid={!!errors.addressLine1}
												name="addressLine1"
												onChange={handleChange}
												placeholder="House/Lot/Block No./Street"
												value={addressLine1}
											/>
										</EuiFormRow>
										<EuiFormRow
											// error={errors.addressLine2}
											// isInvalid={!!errors.addressLine2}
											label="Address Line 2"
										>
											<EuiFieldText
												data-testid="addressLine2-input"
												id="addressLine2"
												// isInvalid={!!errors.addressLine2}
												name="addressLine2"
												onChange={handleChange}
												placeholder="Brgy/Province/City"
												value={addressLine2}
											/>
										</EuiFormRow>
									</EuiFlexItem>
								</EuiFlexGroup>
								{/* <EuiSpacer />
								<EuiText>
									<h3>Discount</h3>
								</EuiText>
								<EuiFormRow label={t('products.discountValue')}>
									<EuiFieldNumber
										data-testid="discountValue-input"
										id="discountValue"
										// isInvalid={!!errors.discountValue}
										name="discountValue"
										onChange={handleChange}
										value={discountValue}
									/>
								</EuiFormRow> */}
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiSpacer size="l" />
								<EuiSpacer size="l" />
								<EuiSpacer size="l" />
								<EuiFlexItem>
									<EuiText>{renderFiles()}</EuiText>
								</EuiFlexItem>
							</EuiFlexItem>
						</EuiFlexGrid>
					</EuiFlexItem>
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={() => {
								onClose();
								resetForm({ values: initialValues });
							}}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="checkInCircleFilled"
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

export default AddUser;
