import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	EuiAvatar,
	EuiFlexGroup,
	EuiFlexItem,
	EuiHeaderSectionItemButton,
	EuiLink,
	EuiPopover,
	EuiText,
} from '@elastic/eui';
import _ from 'lodash';

import { logoutAction } from 'modules/auth/auth.actions';

const HeaderUserMenu = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { user } = useSelector((s) => s.auth);
	const [isOpen, setIsOpen] = useState(false);
	const onLogout = () => dispatch(logoutAction());

	const onMenuButtonClick = () => {
		setIsOpen(!isOpen);
	};

	const closeMenu = () => {
		setIsOpen(false);
	};

	let name = user.username;

	if (_.get(user, 'details.firstName', null)) {
		name = _.get(user, 'details.firstName');
	}

	if (_.get(user, 'details.lastName', null)) {
		name += ` ${_.get(user, 'details.lastName')}`;
	}

	const avatarButton = (
		<EuiHeaderSectionItemButton
			aria-label="Account menu"
			onClick={onMenuButtonClick}
		>
			<EuiAvatar name={name} size="s" />
		</EuiHeaderSectionItemButton>
	);

	return (
		<EuiPopover
			anchorPosition="downRight"
			button={avatarButton}
			closePopover={closeMenu}
			isOpen={isOpen}
			panelPaddingSize="none"
		>
			<div style={{ width: 300 }}>
				<EuiFlexGroup
					className="euiHeaderProfile"
					gutterSize="m"
					responsive={false}
				>
					<EuiFlexItem
						grow={false}
						style={{ justifyContent: 'center' }}
					>
						<EuiAvatar name={name} size="xl" />
					</EuiFlexItem>

					<EuiFlexItem style={{ justifyContent: 'center' }}>
						<EuiText>
							<p>{name}</p>
						</EuiText>
						<EuiLink onClick={onLogout}>
							{t('dashboard.logout')}
						</EuiLink>
					</EuiFlexItem>
				</EuiFlexGroup>
			</div>
		</EuiPopover>
	);
};

export default HeaderUserMenu;
