import {
	FIND_RX_SUCCESS,
	CREATE_RX_DASHBOARD_SUCCESS,
	CREATE_RX_SUCCESS,
} from 'constants/actionTypes';

export const createRxSuccess = (data) => ({
	type: CREATE_RX_SUCCESS,
	data,
});

export const findRxSuccess = (data) => ({
	type: FIND_RX_SUCCESS,
	data,
});

export const createRxDashboardSuccess = (data) => ({
	type: CREATE_RX_DASHBOARD_SUCCESS,
	data,
});

export const getRxSuccess = (data) => ({
	type: FIND_RX_SUCCESS,
	data,
});
