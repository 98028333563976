import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
	EuiForm,
	EuiFormRow,
	EuiFieldText,
	EuiModal,
	EuiOverlayMask,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiFilePicker,
	EuiImage,
	EuiFlexItem,
	EuiModalFooter,
	EuiButtonEmpty,
	EuiButton,
	EuiTitle,
	EuiSpacer,
	EuiHorizontalRule,
	EuiFlexGroup,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import PropTypes from 'prop-types';

import { createLicense } from 'modules/licenses/licenses.fetch';
import { logoutAction, updateUserDetails } from 'modules/auth/auth.actions';
import { getUserDetails } from 'modules/auth/auth.fetch';
import Callout from 'components/Callout';
import { DOCTOR } from 'components/roles';
import protectHOC from 'components/protectHOC';
import { ROLE_PATH } from 'constants/protect';

import { addToast } from './dashboard.actions';
// import { addToast } from 'modules/dashboard/dashboard.actions';

const ValidationSchema = Yup.object().shape({
	prcLicenseNumber: Yup.string().required('License Number is required.'),
	prcImage: Yup.string().required('License Photo is required.').nullable(),
	s2LicenseNumber: Yup.string(),
	s2Image: Yup.string()
		.when('s2LicenseNumber', {
			is: (num) => num && num.length > 0,
			then: Yup.string()
				.required('License Photo is required.')
				.nullable(),
		})
		.nullable(),
});

const ValidationModal = ({ pending, visible }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [prcImage, setPrcImage] = useState(null);
	const [s2Image, setS2Image] = useState(null);

	const {
		errors,
		handleChange,
		handleSubmit,
		setFieldValue,
		values,
	} = useFormik({
		initialValues: {
			prcLicenseNumber: '',
			prcImage: '',
			s2LicenseNumber: '',
			s2Image: '',
		},
		onSubmit: async (data) => {
			try {
				setIsLoading(true);
				// eslint-disable-next-line default-case
				await createLicense({
					licenseNumber: data.prcLicenseNumber,
					image: prcImage,
					type: 'PRC',
				});

				if (data.s2LicenseNumber) {
					await createLicense({
						licenseNumber: data.s2LicenseNumber,
						image: s2Image,
						type: 'S2',
					});
				}

				const { data: user } = await getUserDetails();

				dispatch(updateUserDetails(user));

				addToast('License submitted for validation', null, 'success');
			} catch (err) {
				// log or do something with the error
				setError(err.message || err);
			} finally {
				setIsLoading(false);
			}
		},
		validationSchema: ValidationSchema,
	});

	const setFile = (key, value) => {
		if (value.length > 0) {
			if (key === 's2Image') setS2Image(value[0]);
			if (key === 'prcImage') setPrcImage(value[0]);

			setFieldValue(key, value[0].name);
		} else {
			if (key === 's2Image') setS2Image(null);
			if (key === 'prcImage') setPrcImage(null);
			setFieldValue(key, null);
		}
	};

	const renderFiles = (file) => {
		if (!file) return null;

		const url = URL.createObjectURL(file);

		return <EuiImage allowFullScreen={false} size="m" url={url} />;
	};

	const logout = () => dispatch(logoutAction());

	if (!visible) return null;

	return (
		<EuiOverlayMask>
			<EuiModal
				// TODO: move to stylesheet
				style={{ position: 'relative', zIndex: 1, width: 800 }}
			>
				<EuiModalHeader>
					<EuiModalHeaderTitle>
						{t('validationModal.title')}
					</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiModalBody>
					<EuiForm className="validation-form">
						<Callout color="danger" value={error} />
						{pending ? (
							<Callout
								color="warning"
								value="You have pending license validation"
							/>
						) : (
							<EuiFlexGroup>
								<EuiFlexItem>
									<EuiTitle size="xs">
										<h3>{`${t(
											'validationModal.prc',
										)} License`}</h3>
									</EuiTitle>
									<EuiHorizontalRule margin="xs" />
									<EuiFormRow
										error={errors.prcLicenseNumber}
										isInvalid={
											errors.prcLicenseNumber || false
										}
										label={t(
											'validationModal.licenseNumber',
										)}
									>
										<EuiFieldText
											id="prcLicenseNumber"
											isInvalid={
												errors.prcLicenseNumber || false
											}
											name="prcLicenseNumber"
											onChange={handleChange}
											placeholder={t(
												'validationModal.licenseNumber',
											)}
											value={values.prcLicenseNumber}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={errors.prcImage}
										isInvalid={errors.prcImage || false}
									>
										<EuiFilePicker
											aria-label="PRC License Photo"
											display="default"
											id="prcImage"
											initialPromptText="Photo of your PRC License Here"
											isInvalid={errors.prcImage || false}
											name="prcImage"
											onChange={(e) =>
												setFile('prcImage', e)
											}
										/>
									</EuiFormRow>
									<EuiSpacer />
									<EuiFormRow>
										<EuiFlexGroup
											alignItems="center"
											justifyContent="center"
										>
											{renderFiles(prcImage)}
										</EuiFlexGroup>
									</EuiFormRow>
								</EuiFlexItem>
								<EuiFlexItem>
									<EuiTitle size="xs">
										<h3>
											{`${t(
												'validationModal.s2',
											)} License`}{' '}
											<span className="subtitle">
												(optional)
											</span>
										</h3>
									</EuiTitle>
									<EuiHorizontalRule margin="xs" />
									<EuiFormRow
										error={errors.s2LicenseNumber}
										isInvalid={
											errors.s2LicenseNumber || false
										}
										label={t(
											'validationModal.licenseNumber',
										)}
									>
										<EuiFieldText
											id="s2LicenseNumber"
											isInvalid={
												errors.s2LicenseNumber || false
											}
											name="s2LicenseNumber"
											onChange={handleChange}
											placeholder={t(
												'validationModal.licenseNumber',
											)}
											value={values.s2LicenseNumber}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={errors.s2Image}
										isInvalid={errors.s2Image || false}
									>
										<EuiFilePicker
											aria-label="PRC License Photo"
											display="default"
											id="s2Image"
											initialPromptText="Photo of your S2 License Here"
											isInvalid={errors.s2Image || false}
											name="s2Image"
											onChange={(e) =>
												setFile('s2Image', e)
											}
											onEmptied={() =>
												setFile('s2Image', null)
											}
										/>
									</EuiFormRow>
									<EuiSpacer />
									<EuiFormRow>
										<EuiFlexGroup
											alignItems="center"
											justifyContent="center"
										>
											{renderFiles(s2Image)}
										</EuiFlexGroup>
									</EuiFormRow>
								</EuiFlexItem>
							</EuiFlexGroup>
						)}
					</EuiForm>
					<EuiSpacer size="l" />
				</EuiModalBody>
				<EuiModalFooter>
					<EuiButtonEmpty color="danger" onClick={logout}>
						{t('dashboard.logout')}
					</EuiButtonEmpty>
					{!pending && (
						<EuiButton
							color="primary"
							fill
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							Submit
						</EuiButton>
					)}
				</EuiModalFooter>
			</EuiModal>
		</EuiOverlayMask>
	);
};

ValidationModal.propTypes = {
	pending: PropTypes.bool.isRequired,
	visible: PropTypes.bool,
};

ValidationModal.defaultProps = {
	visible: false,
};

export default protectHOC([DOCTOR.key], ROLE_PATH)(ValidationModal);
