import React from 'react';
import { Switch, Route } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { IconContext } from 'react-icons';
import { useSelector } from 'react-redux';

import AuthLayout from 'components/layouts/AuthLayout';
import DashboardLayout from 'components/layouts/DashboardLayout';
import RegistrationLayout from 'components/layouts/RegistrationLayout';
import DocumentLayout from 'components/layouts/DocumentLayout';
import Login from 'modules/auth/Login';
import Dashboard from 'modules/dashboard/Dashboard';
import ForgotPassword from 'modules/auth/ForgotPassword';
import ResetPassword from 'modules/auth/ResetPassword';
import Registration from 'modules/auth/Registration';
import { attachToken } from '../utils/api';
import TermsAndConditions from './_global/TermsAndConditions';
import PrivacyPolicy from './_global/PrivacyPolicy';

function App() {
	useSelector(({ auth }) => auth.isLoggedIn && attachToken(auth.jwt));

	const routes = [
		{
			component: Login,
			exact: true,
			id: 'login',
			layout: AuthLayout,
			path: '/login',
		},
		{
			component: ForgotPassword,
			exact: true,
			id: 'forgot-password',
			layout: AuthLayout,
			path: '/forgot-password',
		},
		{
			component: ResetPassword,
			exact: true,
			id: 'reset-password',
			layout: AuthLayout,
			path: '/reset-password',
		},
		{
			component: Registration,
			exact: true,
			id: 'registration',
			layout: RegistrationLayout,
			path: '/register',
		},
		{
			component: TermsAndConditions,
			exact: true,
			id: 'terms-and-conditions',
			path: '/terms-and-conditions',
			layout: DocumentLayout,
		},
		{
			component: PrivacyPolicy,
			exact: true,
			id: 'privacy-policy',
			path: '/privacy-policy',
			layout: DocumentLayout,
		},
		{
			component: Dashboard,
			exact: false,
			id: 'dashboard',
			layout: DashboardLayout,
			path: '/',
		},
	];

	return (
		<HelmetProvider>
			<div className="App">
				<IconContext.Provider value={{ color: '#888888' }}>
					<Helmet
						defaultTitle="ERx Dashboard"
						titleTemplate="%s | ERx Dashboard"
					/>
					<Router>
						<Switch>
							{routes.map(
								({
									component: Component,
									exact,
									id,
									layout: Layout,
									path,
								}) => (
									<Route
										key={`${id}-route`}
										exact={exact}
										path={path}
										render={(history, ...props) => (
											<Layout history={history}>
												{/* eslint-disable-next-line react/jsx-props-no-spreading */}
												<Component {...props} />
											</Layout>
										)}
									/>
								),
							)}
						</Switch>
					</Router>
				</IconContext.Provider>
			</div>
		</HelmetProvider>
	);
}

export default App;
