import React from 'react';
import { useTranslation } from 'react-i18next';

import { EuiHealth } from '@elastic/eui';

import PropTypes from 'prop-types';

const OrderStatus = ({ status }) => {
	const { t } = useTranslation();
	const checkStatus = status ? status.toLowerCase() : '';
	let color;

	switch (checkStatus) {
		case 'completed':
			color = 'primary';
			break;
		case 'confirmed':
			color = 'secondary';
			break;
		case 'approved':
			color = 'secondary';
			break;
		case 'pendingpayment':
		case 'processing':
		case 'open':
			color = 'warning';
			break;
		case 'readyForShipping':
			color = 'blue';
			break;

		case 'cancelled':
		case 'rejected':
			color = 'danger';
			break;

		default:
			color = 'disabled';
	}

	return (
		<EuiHealth color={color}>
			{status ? t(`orders.status.${status}`) : 'No Status'}
		</EuiHealth>
	);
};

OrderStatus.propTypes = {
	status: PropTypes.oneOf([
		'open',
		'pendingApproval',
		'approved',
		'rejected',
		'processing',
		'pendingPayment',
		'readyForShipping',
		'cancelled',
		'disabled',
		'completed',
	]),
};

OrderStatus.defaultProps = {
	status: null,
};

export default OrderStatus;
