// should be able to accept query parameters
import api from '../../utils/api';

export const createOrder = async (payload) => api.post('/orders', payload);

export const getOrders = async () => api.get('/orders');

export const getOrder = async (id) => api.get(`/orders/${id}`);

export const getOrdersByParameters = async (params) =>
	api.get(`/orders`, { params });

export const updateOrder = async (id, payload) =>
	api.put(`/orders/${id}`, payload);

export const updateOrderStatus = async (id, status) =>
	api.put(`/orders/${id}/status?status=${status}`);

export const getLineItem = async (id) => api.get(`/line-items/${id}`);
