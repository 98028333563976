import api from 'utils/api';

export const getDoctor = async (id) => {
	return api.get(`/doctors/${id}`);
};

export const getContactDetailsByIds = async (ids) => {
	if (!ids || !ids.length) {
		return Promise.resolve([]);
	}

	return api.get('/contact-details', {
		params: {
			id_in: ids,
		},
	});
};

export const getRegions = async (params, options = {}) => {
	return api.get('/regions', { ...options, params });
};

export const getProvinces = async (params, options = {}) => {
	return api.get('/provinces', { ...options, params });
};

export const getCities = async (params, options = {}) => {
	return api.get('/cities', { ...options, params });
};

export const getBarangays = async (params, options = {}) => {
	return api.get('/barangays', { ...options, params });
};
