import {
	LOGIN_SUCCESS,
	LOGOUT_SUCCESS,
	UPDATE_USER_DETAILS,
} from 'constants/actionTypes';
import { detachToken } from '../../utils/api';

export const loginAction = (data) => {
	return {
		type: LOGIN_SUCCESS,
		data,
	};
};

export const logoutAction = () => {
	detachToken();

	return {
		type: LOGOUT_SUCCESS,
	};
};

export const updateUserDetails = (data) => {
	return {
		type: UPDATE_USER_DETAILS,
		data,
	};
};
