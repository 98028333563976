import React from 'react';
import { useTranslation } from 'react-i18next';

import { EuiHealth } from '@elastic/eui';

import PropTypes from 'prop-types';

const RxStatus = ({ status }) => {
	const { t } = useTranslation();
	const checkStatus = status ? status.toLowerCase() : '';
	let color;

	switch (checkStatus) {
		case 'served':
			color = 'primary';
			break;

		case 'new':
			color = 'secondary';
			break;

		case 'partial':
			color = 'warning';
			break;

		case 'encoded':
			color = 'accent';
			break;

		default:
			color = 'disabled';
	}

	return (
		<EuiHealth color={color}>
			{status ? t(`prescriptions.status.${status}`) : 'No Status'}
		</EuiHealth>
	);
};

RxStatus.propTypes = {
	status: PropTypes.oneOf(['new', 'encoded', 'served', 'partial']),
};

RxStatus.defaultProps = {
	status: null,
};

export default RxStatus;
