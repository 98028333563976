import React from 'react';
import { connect } from 'react-redux';

import _ from 'lodash';

import PropTypes from 'prop-types';

export default (allowedRoles = [], reduxRolePath) => (Component) => {
	const ProtectHOC = ({ role, ...props }) => {
		const allowed = allowedRoles.find((ar) => role === ar);

		if (!allowed || !allowed.length) return null;

		// eslint-disable-next-line react/jsx-props-no-spreading
		return <Component {...props} />;
	};

	ProtectHOC.propTypes = {
		role: PropTypes.string.isRequired,
	};

	return connect((state) => ({ role: _.get(state, reduxRolePath) }))(
		ProtectHOC,
	);
};
