import api, { attachToken, detachToken } from 'utils/api';

export const postLogin = async (credentials) => {
	try {
		const { data } = await api.post('/auth/local', credentials);

		attachToken(data.jwt);

		const { data: additionalData } = await api.get('/users/me');

		return {
			...data,
			user: {
				...data.user,
				...additionalData,
			},
		};
	} catch (err) {
		detachToken();
		throw new Error('Incorrect username or password.');
	}
};

export const postForgotPassword = async (email) => {
	try {
		await api.post('/auth/forgot-password', { email });

		return { success: true };
	} catch (err) {
		throw new Error(`Failed to send reset password link to: ${email}`);
	}
};

export const postResetPassword = async (data) => {
	return api.post('/auth/reset-password', data);
};

export const postDoctorRegistration = async (payload) => {
	return api.post('/doctors/register', payload);
};

export const getUserDetails = async () => {
	return api.get('/users/me');
};
