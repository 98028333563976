import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Page from 'components/Page';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
	EuiTitle,
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	// EuiDatePicker,
	// EuiDatePickerRange,
	EuiImage,
	EuiFieldText,
} from '@elastic/eui';
// import moment from 'moment';
import _ from 'lodash';
// import { DATE_FORMAT } from 'constants/formats';
// import Table from 'components/Table';
import NoProfilePhoto from 'assets/images/no-profile-photo.png';
import ConfirmationModal from 'components/ConfirmationModal';
import { addToast } from '../dashboard/dashboard.actions';
import { getPatient, updatePatient } from './patients.fetch';

const PatientSchema = Yup.object().shape({});

const User = () => {
	const { t } = useTranslation();
	const { patientId } = useParams();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	// const [startDate, setStartDate] = useState('');
	// const [endDate, setEndDate] = useState('');
	// const [selected, setSelected] = useState([]);
	const [patient, setPatient] = useState({});
	const [hasChanges, setHasChanges] = useState(false);
	const [
		isConfirmCancelChangesModalVisible,
		setIsConfirmCancelChangesModalVisible,
	] = useState(false);

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		values,
	} = useFormik({
		initialValues: {
			firstName: patient.firstName,
			middleName: patient.middleName,
			lastName: patient.lastName,
			address: patient.address || {
				line1: '',
				line2: '',
				city: '',
				state: '',
				postal: '',
			},
			contactDetails: patient.contactDetails || [],
			attachments: patient.attachments || {
				url: NoProfilePhoto,
			},
		},
		validationSchema: PatientSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		// eslint-disable-next-line no-unused-vars
		onSubmit: async (data) => {
			setIsLoading(true);
			try {
				const payload = {
					firstName: data.firstName,
					middleName: data.middleName,
					lastName: data.lastName,
					address: {
						id: data.address.id,
						line1: data.address.line1,
						line2: data.address.line2,
						city: data.address.city,
						state: data.address.state,
						postal: data.address.postal,
					},
				};
				const result = await updatePatient(patientId, payload);
				setPatient(result.data);
				dispatch(
					addToast(
						'Patient updated',
						'Changes Saved',
						'success',
						'check',
					),
				);
			} catch (err) {
				setError(err.message || 'Something went wrong');
				resetForm({ values: initialValues });
			} finally {
				setError(null);
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	const {
		address,
		attachments,
		contactDetails,
		firstName,
		lastName,
		middleName,
	} = values;

	useEffect(() => {
		async function fetchPatient() {
			try {
				const result = await getPatient(patientId);
				setPatient(result.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchPatient();
	}, [patientId]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	const handleCancel = () => setIsConfirmCancelChangesModalVisible(true);

	const confirmCancelChangesModal = isConfirmCancelChangesModalVisible ? (
		<ConfirmationModal
			message="Cancel Changes?"
			onClose={() => {
				setIsConfirmCancelChangesModalVisible(false);
			}}
			onConfirm={() => {
				resetForm({ values: initialValues });
				setIsConfirmCancelChangesModalVisible(false);
			}}
			title="Confirm"
		/>
	) : null;

	// const clearFilters = async () => {
	// 	setStartDate(null);
	// 	setEndDate(null);

	// 	try {
	// 		setIsLoading(true);
	// 		const result = await getPatient(patientId);
	// 		setPatient(result.data);
	// 	} catch (err) {
	// 		setError(err.message || 'Something went wrong');
	// 	} finally {
	// 		setError(null);
	// 		setIsLoading(false);
	// 	}
	// };

	// const selection = {
	// 	selectable: () => true,
	// 	onSelectionChange: (select) => {
	// 		setSelected(select);
	// 	},
	// 	selectableMessage: () => undefined,
	// 	initialSelected: selected,
	// };

	// const renderToolsLeft = () => {
	// 	if (!selection.length) {
	// 		return null;
	// 	}

	// 	return (
	// 		<EuiButton color="danger" fill={false} iconType="trash">
	// 			Delete
	// 		</EuiButton>
	// 	);
	// };

	// const renderToolsRight = () => [
	// 	<EuiFlexItem key="datePicker" grow={false} justifyContent="center">
	// 		<EuiDatePickerRange
	// 			endDateControl={
	// 				<EuiDatePicker
	// 					minDate={startDate ? moment(startDate) : null}
	// 					onChange={(e) => setEndDate(e.format(DATE_FORMAT))}
	// 					placeholder={t('orders.endDate')}
	// 					value={endDate}
	// 				/>
	// 			}
	// 			startDateControl={
	// 				<EuiDatePicker
	// 					onChange={(e) => setStartDate(e.format(DATE_FORMAT))}
	// 					placeholder={t('orders.startDate')}
	// 					value={startDate}
	// 				/>
	// 			}
	// 		/>
	// 	</EuiFlexItem>,
	// 	<EuiFlexItem key="clearFilter" grow={false} justifyContent="center">
	// 		<EuiButton color="disabled" iconType="broom" onClick={clearFilters}>
	// 			Clear Filters
	// 		</EuiButton>
	// 	</EuiFlexItem>,
	// ];

	// const search = {
	// 	box: {
	// 		incremental: true,
	// 	},
	// 	toolsLeft: renderToolsLeft(),
	// 	toolsRight: renderToolsRight(),
	// 	filters: [
	// 		// {
	// 		// 	type: 'field_value_selection',
	// 		// 	field: 'status',
	// 		// 	name: 'Status',
	// 		// 	multiSelect: false,
	// 		// 	options: [
	// 		// 		{
	// 		// 			value: 'open',
	// 		// 			name: 'open',
	// 		// 			view: <OrderStatus status="open" />,
	// 		// 		},
	// 		// 	],
	// 		// },
	// 	],
	// };

	// const columns = [
	// 	{
	// 		field: 'id',
	// 		name: t('patients.recordId'),
	// 		sortable: true,
	// 		width: '10%',
	// 	},
	// 	{
	// 		field: 'description',
	// 		name: t('patients.logsDescription'),
	// 		sortable: true,
	// 		width: '10%',
	// 	},
	// 	{
	// 		field: 'date',
	// 		name: t('general.date'),
	// 		sortable: true,
	// 		width: '10%',
	// 	},
	// 	{
	// 		field: 'type',
	// 		name: t('general.type'),
	// 		sortable: true,
	// 		width: '10%',
	// 	},
	// 	{
	// 		width: '10%',
	// 		actions: [
	// 			{
	// 				name: 'Edit',
	// 				description: 'Edit',
	// 				onClick: () => {},
	// 				icon: 'pencil',
	// 				type: 'icon',
	// 				color: 'primary',
	// 				isPrimary: true,
	// 			},
	// 			{
	// 				name: 'Delete',
	// 				description: 'Delete',
	// 				onClick: () => {},
	// 				icon: 'trash',
	// 				type: 'icon',
	// 				color: 'danger',
	// 				isPrimary: true,
	// 			},
	// 		],
	// 	},
	// ];

	// eslint-disable-next-line no-unused-vars
	const disableButton = Object.keys(errors).length > 0;

	return (
		<Page
			headerRight={
				<EuiFlexGroup direction="row" gutterSize="m">
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							disabled={isLoading || hasChanges}
							iconType="crossInACircleFilled"
							onClick={handleCancel}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading || hasChanges}
							fill
							iconType="checkInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			}
			stickyHeader
			title={t('patients.titlePatient', { id: patient.Id })}
		>
			<EuiTitle size="xs">
				<h3>{`${patient.firstName} 
				${patient.middleName || ''} 
				${patient.lastName}`}</h3>
			</EuiTitle>
			<EuiSpacer size="m" />
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />
			<EuiFlexGroup direction="row" justifyContent="flexStart">
				<EuiFlexItem style={{ maxWidth: 1200 }}>
					<EuiForm>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiImage
									allowFullScreen={false}
									alt="Accessible image alt goes here"
									// caption="Medium"
									hasShadow
									size="m"
									url={attachments.url}
								/>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow
									label={t('users.firstName')}
									type="column"
								>
									<EuiFieldText
										data-testid="firstName-input"
										id="firstName"
										name="firstName"
										onChange={handleChange}
										value={firstName}
									/>
								</EuiFormRow>
								<EuiFormRow
									label={t('users.middleName')}
									type="column"
								>
									<EuiFieldText
										data-testid="middleName-input"
										id="middleName"
										name="middleName"
										onChange={handleChange}
										value={middleName}
									/>
								</EuiFormRow>
								<EuiFormRow
									label={t('users.lastName')}
									type="column"
								>
									<EuiFieldText
										data-testid="lastName-input"
										id="lastName"
										name="lastName"
										onChange={handleChange}
										value={lastName}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow label={t('general.address.line1')}>
									<EuiFieldText
										data-testid="address.line1-input"
										id="address.line1"
										name="address.line1"
										onChange={handleChange}
										value={address.line1}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('general.address.line2')}>
									<EuiFieldText
										data-testid="address.line2-input"
										id="address.line2"
										name="address.line2"
										onChange={handleChange}
										value={address.line2}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('general.address.city')}>
									<EuiFieldText
										data-testid="address.city-input"
										id="address.city"
										name="address.city"
										onChange={handleChange}
										value={address.city}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('general.address.state')}>
									<EuiFieldText
										data-testid="address.state-input"
										id="address.state"
										name="address.state"
										onChange={handleChange}
										value={address.state}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('general.address.postal')}>
									<EuiFieldText
										data-testid="address.postal-input"
										id="address.postal"
										name="address.postal"
										onChange={handleChange}
										value={address.postal}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem>
								{contactDetails &&
									contactDetails.length > 0 &&
									contactDetails.map(
										(contactDetail, index) => (
											<EuiFormRow
												key={contactDetail.id}
												label={`${t(
													contactDetail.type ===
														'phone' ||
														contactDetail.type ===
															'mobile'
														? 'patients.contactNumber'
														: 'patients.email',
												)}`}
											>
												<EuiFieldText
													data-testid={`contactDetails[${index}]-input`}
													id={`contactDetails[${index}].value`}
													name={`contactDetails[${index}].value`}
													onChange={handleChange}
													value={contactDetail.value}
												/>
											</EuiFormRow>
										),
									)}
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiForm>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiSpacer size="m" />
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />
			{/* <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
				<EuiFlexItem>
					<EuiTitle size="xs">
						<h1>{t('patients.patientLogs')}</h1>
					</EuiTitle>
				</EuiFlexItem>
			</EuiFlexGroup>
			<Table
				columns={columns}
				isExpandable
				isSelectable
				itemId="id"
				items={patientLogs}
				search={search}
				selection={selection}
			/> */}
			{confirmCancelChangesModal}
		</Page>
	);
};

export default User;
