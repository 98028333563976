import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Document, Page as DocumentPage, pdfjs } from 'react-pdf';

import {
	EuiButtonIcon,
	EuiDescriptionList,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFlyout,
	EuiFlyoutBody,
	EuiFlyoutHeader,
	EuiImage,
	EuiSpacer,
	EuiText,
	EuiPanel,
	EuiLink,
	EuiFormRow,
} from '@elastic/eui';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { ROLE_PATH } from 'constants/protect';
import { DOCTOR } from 'components/roles';
import Table from 'components/Table';
import { formatDate } from 'utils/helpers';

import RxStatus from './components/RxStatus';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ViewPrescription = ({ data, onClose, visible }) => {
	const { t } = useTranslation();
	const { role } = useSelector((state) => ({
		role: _.get(state, ROLE_PATH),
	}));
	const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [pageNumber, setPageNumber] = useState(1);
	// eslint-disable-next-line no-unused-vars
	const [numPages, setNumPages] = useState(null);

	if (!visible) return null;

	const { attachments } = data;

	const toggleDetails = (item) => {
		const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
		if (itemIdToExpandedRowMapValues[item.id]) {
			delete itemIdToExpandedRowMapValues[item.id];
		} else {
			itemIdToExpandedRowMapValues[item.id] = (
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiDescriptionList
							compressed
							listItems={[
								{
									title: 'Generic Name',
									description: item.generic,
								},
								{
									title: 'Brand',
									description: item.brand,
								},
								{
									title: 'Formulation',
									description: item.formulation,
								},
							]}
							type="column"
						/>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiDescriptionList
							compressed
							listItems={[
								{
									title: 'Prescribed Quantity',
									description: item.quantity,
								},
								{
									title: 'Remaining Quantity',
									description: item.currentQty,
								},
								{
									title: 'Sig',
									description: item.sig,
								},
							]}
							type="column"
						/>
					</EuiFlexItem>
				</EuiFlexGroup>
			);
		}
		setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
	};

	const onDocumentLoadSuccess = ({ pages }) => {
		setNumPages(pages);
	};
	const renderFiles = (files) => {
		if (!files.length) return null;

		const toDisplay = files.map((file) =>
			file.mime === 'application/pdf' ? (
				<EuiFlexItem key={file.name} grow={false}>
					<EuiFormRow>
						<EuiLink
							href={process.env.REACT_APP_API_URL + file.url}
							target="_blank"
						>
							<EuiPanel>
								<Document
									file={
										process.env.REACT_APP_API_URL + file.url
									}
									onLoadSuccess={onDocumentLoadSuccess}
									renderMode="canvas"
								>
									<DocumentPage
										pageNumber={pageNumber}
										width="120"
									/>
								</Document>
							</EuiPanel>
						</EuiLink>
					</EuiFormRow>
				</EuiFlexItem>
			) : (
				<EuiFlexItem key={file.name} grow={false}>
					<EuiPanel>
						<EuiImage
							allowFullScreen
							size="s"
							url={process.env.REACT_APP_API_URL + file.url}
						/>
					</EuiPanel>
				</EuiFlexItem>
			),
		);

		return toDisplay;
	};

	const descriptionList = [
		{
			title: 'Patient',
			description: `${data.patient.firstName} ${data.patient.lastName}`,
		},
		{
			title: t('general.date'),
			description: formatDate(data.createdAt),
		},
		{
			title: t('general.status'),
			description: <RxStatus status={data.status} />,
		},
		{
			title: t('general.type'),
			description: data.type.toUpperCase(),
		},
	];

	if (role !== DOCTOR.key) {
		descriptionList.push({
			title: t('general.doctor'),
			description: `${data.doctor.firstName} ${data.doctor.lastName}`,
		});
	}

	if (role === DOCTOR.key) {
		descriptionList.push({
			description: data.vendor.name,
			title: 'Pharmacy',
		});
	}

	const columns = [
		{
			field: 'generic',
			name: 'Generic Name',
			type: 'string',
			width: '40%',
		},
		{
			field: 'brand',
			name: 'Brand',
			width: '30%',
		},
		{
			field: 'formulation',
			name: 'Formulation',
			width: '20%',
		},
		{
			align: 'right',
			isExpander: true,
			render: (item) => (
				<EuiButtonIcon
					aria-label={
						itemIdToExpandedRowMap[item.id] ? 'Collapse' : 'Expand'
					}
					iconType={
						itemIdToExpandedRowMap[item.id]
							? 'arrowUp'
							: 'arrowDown'
					}
					onClick={() => toggleDetails(item)}
				/>
			),
		},
	];

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Order"
			onClose={() => {
				onClose();
				// resetForm({ values: initialValues });
			}}
			size="m"
			// TODO: move to stylesheet
			style={{ zIndex: 0 }}
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>{t('prescriptions.code', data)}</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiDescriptionList
							compressed
							listItems={descriptionList}
							type="column"
						/>
					</EuiFlexItem>
					{attachments && renderFiles(attachments)}
				</EuiFlexGroup>
				<EuiSpacer />
				<Table
					columns={columns}
					isExpandable
					itemId="id"
					itemIdToExpandedRowMap={itemIdToExpandedRowMap}
					items={data.prescriptionItems}
				/>
			</EuiFlyoutBody>
		</EuiFlyout>
	);
};

ViewPrescription.propTypes = {
	data: PropTypes.shape({
		createdAt: PropTypes.string,
		clinic: PropTypes.shape({
			name: PropTypes.string,
		}),
		doctor: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		attachments: PropTypes.arrayOf(
			PropTypes.shape({
				url: PropTypes.string,
				mime: PropTypes.string,
				name: PropTypes.string,
			}),
		),
		status: PropTypes.string,
		type: PropTypes.string,
		patient: PropTypes.shape({
			firstName: PropTypes.string,
			lastName: PropTypes.string,
		}),
		prescriptionItems: PropTypes.arrayOf(
			PropTypes.shape({
				generic: PropTypes.string,
				brand: PropTypes.string,
			}),
		),
		vendor: PropTypes.shape({
			name: PropTypes.string,
		}),
	}).isRequired,
	onClose: PropTypes.func.isRequired,
	status: PropTypes.string.isRequired,
	visible: PropTypes.bool,
};

ViewPrescription.defaultProps = {
	visible: false,
};

export default ViewPrescription;
