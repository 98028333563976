import api from 'utils/api';

export const getVendors = async () => api.get('/vendors');

/**
 * Get Vendors by City id or code provided
 * @param {Object} params - { id: cityId } or { code: cityCode }
 */
export const getVendorsByCity = async (params) => {
	return api.get('/vendors/city', { params });
};

export const getVendorsByAddress = async (params) => {
	return api.get('/vendors/near', { params });
};
