import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EuiFlexItem, EuiButton } from '@elastic/eui';

import { ROLE_PATH } from 'constants/protect';
import { DOCTOR } from 'components/roles';
import protectHOC from 'components/protectHOC';
import AddPrescription from '../AddPrescription';

const AddRxButton = () => {
	const { t } = useTranslation();
	const [addVisible, setAddVisible] = useState(false);

	return (
		<>
			<EuiFlexItem>
				<EuiButton
					fill
					iconType="plusInCircleFilled"
					onClick={() => setAddVisible(true)}
				>
					{t('prescriptions.addRx')}
				</EuiButton>
			</EuiFlexItem>
			<AddPrescription
				onClose={() => {
					setAddVisible(false);
				}}
				visible={addVisible}
			/>
		</>
	);
};

export default protectHOC([DOCTOR.key], ROLE_PATH)(AddRxButton);
