import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { EuiTitle, EuiHorizontalRule, EuiLink } from '@elastic/eui';
import moment from 'moment';
import _ from 'lodash';

import {
	RECENT_PRESCRIPTIONS_LIMIT,
	RECENT_PRESCRIPTIONS_DEFAULT_SORT,
} from 'constants/dashboard';
import Table from 'components/Table';
import protectHOC from 'components/protectHOC';
import { DOCTOR, ADMIN } from 'components/roles';
import { ROLE_PATH } from 'constants/protect';
import RxStatus from 'modules/prescriptions/components/RxStatus';
import { getPrescriptions } from 'modules/prescriptions/prescriptions.fetch';
import ViewPrescription from 'modules/prescriptions/ViewPrescription';

import { getDashboardRx } from './dashboard.actions';

const DoctorDashboardContent = () => {
	const { t } = useTranslation();
	const { prescriptions, role } = useSelector((state) => ({
		role: _.get(state, ROLE_PATH),
		prescriptions: _.get(state, 'dashboard.prescriptions'),
	}));
	const dispatch = useDispatch();
	const [columns, setColumns] = useState([]);
	const [, setError] = useState(null);
	const [, setIsLoading] = useState(false);

	const [selectedRx, setSelectedRx] = useState({
		data: null,
		visible: false,
	});

	useEffect(() => {
		async function fetchPrescriptions() {
			try {
				setIsLoading(true);
				const { data } = await getPrescriptions({
					_limit: RECENT_PRESCRIPTIONS_LIMIT,
					_sort: RECENT_PRESCRIPTIONS_DEFAULT_SORT,
				});
				dispatch(getDashboardRx(data));
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchPrescriptions();
	}, []);

	useEffect(() => {
		if (columns.length) return;

		const cols = [
			{
				field: 'code',
				name: 'Prescription Code',
				sortable: true,
				render: (code, data) => (
					<Link
						onClick={(e) => {
							if (role === DOCTOR.key) {
								e.preventDefault();
								setSelectedRx({ data, visible: true });

								return false;
							}

							return true;
						}}
						to={`/prescriptions/${data.id}`}
					>
						<EuiLink>{code}</EuiLink>
					</Link>
				),
			},
			{
				field: 'patient.firstName',
				name: 'Patient',
				sortable: true,
				render: (firstName, { patient: { lastName } }) =>
					`${firstName} ${lastName}`,
			},
			{
				field: 'vendor.name',
				name: 'Pharmacy',
				sortable: true,
			},
			{
				field: 'status',
				name: t('general.status'),
				sortable: true,
				render: (status) => <RxStatus status={status} />,
			},
			{
				field: 'createdAt',
				name: 'Date',
				dataType: 'date',
				sortable: true,
				render: (createdAt) => moment(createdAt).format('ll'),
			},
		];

		if (role === ADMIN.key) {
			cols.push({
				field: 'branch',
				name: 'Branch',
				sortable: true,
			});
		}

		setColumns(cols);
	}, [columns, role]);

	return (
		<>
			<EuiTitle size="s">
				<h3>{t('dashboard.recentPrescriptions')}</h3>
			</EuiTitle>
			<EuiHorizontalRule margin="s" />
			<Table columns={columns} items={prescriptions} pagination={null} />
			<ViewPrescription
				data={selectedRx.data}
				onClose={() => {
					setSelectedRx({ data: null, visible: false });
				}}
				visible={selectedRx.visible}
			/>
		</>
	);
};

export default protectHOC([DOCTOR.key], ROLE_PATH)(DoctorDashboardContent);
