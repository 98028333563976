import React from 'react';
import { FaFileAlt } from 'react-icons/fa';

import { EuiButton, EuiPortal } from '@elastic/eui';

import PropTypes from 'prop-types';

const Print = ({ children }) => {
	return (
		<>
			<EuiButton onClick={() => window.print()}>
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<FaFileAlt color="#117aac" style={{ marginRight: 5 }} />
					Print
				</div>
			</EuiButton>
			<EuiPortal>
				<div className="print">{children}</div>
			</EuiPortal>
		</>
	);
};

Print.propTypes = {
	children: PropTypes.node.isRequired,
};

export default Print;
