import { nanoid } from 'nanoid';
import {
	APP_DOCK_NAVIGATION,
	APP_UNDOCK_NAVIGATION,
	ADD_TOAST,
	REMOVE_TOAST,
	CLEAR_TOASTS,
	GET_LATEST_RX_SUCCESS,
} from 'constants/actionTypes';

export const dockAction = () => ({
	type: APP_DOCK_NAVIGATION,
});

export const undockAction = () => ({
	type: APP_UNDOCK_NAVIGATION,
});

/**
 *
 * @param {*} title
 * @param {*} text
 * @param {*} color
 * @param {*} iconType
 */
export const addToast = (title, text, color, iconType) => ({
	type: ADD_TOAST,
	toast: {
		id: nanoid(),
		title,
		text,
		color,
		iconType,
	},
});

export const removeToast = (toast) => ({ type: REMOVE_TOAST, toast });

export const clearToast = () => ({ type: CLEAR_TOASTS });

export const getDashboardRx = (data) => ({ type: GET_LATEST_RX_SUCCESS, data });
