import React, { useState } from 'react';

import { EuiFlexItem, EuiButton } from '@elastic/eui';

import PropTypes from 'prop-types';

import { PHARMACIST } from 'components/roles';
import protectHOC from 'components/protectHOC';
import { ROLE_PATH } from 'constants/protect';
import ConvertPrescriptionModal from './ConvertPrescriptionModal';

const CreateOrderButton = ({ disabled, prescription }) => {
	const [modalVisible, setModalVisible] = useState(false);
	const toggleModal = () => setModalVisible(!modalVisible);

	return (
		<>
			<EuiFlexItem grow={false}>
				<EuiButton
					disabled={disabled}
					fullWidth={false}
					iconType="plusInCircle"
					onClick={toggleModal}
					size="m"
				>
					Create Order
				</EuiButton>
			</EuiFlexItem>
			<ConvertPrescriptionModal
				onClose={toggleModal}
				rxData={prescription}
				visible={modalVisible}
			/>
		</>
	);
};

CreateOrderButton.propTypes = {
	disabled: PropTypes.bool,
	// eslint-disable-next-line react/forbid-prop-types
	prescription: PropTypes.object.isRequired,
};

CreateOrderButton.defaultProps = {
	disabled: false,
};

export default protectHOC([PHARMACIST.key], ROLE_PATH)(CreateOrderButton);
