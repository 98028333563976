import initialState from 'store/initialState';
import {
	CREATE_RX_SUCCESS,
	UPDATE_RX_SUCCESS,
	DELETE_RX_SUCCESS,
	FIND_RX_SUCCESS,
} from 'constants/actionTypes';

export default (state = initialState.prescriptions, action = null) => {
	switch (action.type) {
		case FIND_RX_SUCCESS:
			return [...action.data];

		case CREATE_RX_SUCCESS:
			return [action.data, ...state];

		case UPDATE_RX_SUCCESS:
			return state.map((rx) => {
				if (rx.id === action.data.id) return action.data;

				return rx;
			});

		case DELETE_RX_SUCCESS:
			return state.filter((rx) => rx.id !== action.data.id);

		default:
			return state;
	}
};
