export default {
	dashboard: {
		isNavDocked: false,
		toasts: [],
		prescriptions: [],
	},
	auth: {
		isLoggedIn: false,
	},
	prescriptions: [],
};
