import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Page from 'components/Page';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
	EuiTitle,
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiTextArea,
	EuiImage,
	EuiFieldText,
} from '@elastic/eui';
import _ from 'lodash';
import { addToast } from '../dashboard/dashboard.actions';
import { getBranch, updateBranch } from './branches.fetch';

const BranchSchema = Yup.object().shape({
	name: Yup.string()
		.min(2, 'Too Short!')
		.max(50, 'Too Long!')
		.required('Required'),
	line1: Yup.string().required('Required'),
	postal: Yup.string().required('Required'),
	city: Yup.string().required('Required'),
});

const Branch = () => {
	const { t } = useTranslation();
	const { branchId } = useParams();
	const dispatch = useDispatch();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [branch, setBranch] = useState({});
	const [hasChanges, setHasChanges] = useState(false);

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		touched,
		values,
	} = useFormik({
		initialValues: {
			id: branchId,
			name: branch.name,
			line1: branch.address ? branch.address.line1 : '',
			line2: branch.address ? branch.address.line2 : '',
			state: branch.address ? branch.address.state : '',
			city: branch.address ? branch.address.city : '',
			postal: branch.address ? branch.address.postal : '',
			contactNumber: '',
			email: '',
			description: '',
		},
		validationSchema: BranchSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			setIsLoading(true);
			try {
				const payload = {
					name: data.name,
					line1: data.line1,
					line2: data.line2,
					city: data.city,
					state: data.state,
					postal: data.postal,
				};
				const result = await updateBranch(branchId, payload);
				setBranch(result.data);
				dispatch(
					addToast('Success', 'Branch updated', 'success', 'check'),
				);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				resetForm({ values: initialValues });
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	const {
		city,
		contactNumber,
		description,
		email,
		line1,
		line2,
		name,
		postal,
	} = values;

	useEffect(() => {
		async function fetchBranch() {
			try {
				const result = await getBranch(branchId);
				setBranch(result.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchBranch();
	}, [branchId]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	// eslint-disable-next-line no-unused-vars
	const handleDelete = (data) => {};

	const handleCancel = () => {
		resetForm({ values: initialValues });
	};

	// eslint-disable-next-line no-unused-vars
	const disableButton = Object.keys(errors).length > 0;

	return (
		<Page
			headerRight={
				<EuiFlexGroup direction="row" gutterSize="m">
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							disabled={isLoading || hasChanges}
							iconType="crossInACircleFilled"
							onClick={handleCancel}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading || hasChanges}
							fill
							iconType="checkInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			}
			stickyHeader
			title={t('branches.title', { id: branch.Id })}
		>
			<EuiTitle size="xs">
				<h3>{branch.name}</h3>
			</EuiTitle>
			<EuiHorizontalRule margin="xs" />
			<EuiFlexGroup direction="row" justifyContent="spaceBetween">
				<EuiFlexItem style={{ maxWidth: 1500 }}>
					<EuiForm>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiImage
									allowFullScreen={false}
									alt="Accessible image alt goes here"
									// caption="Medium"
									hasShadow
									size="l"
									url="https://pinoyautoblog.com/wp-content/uploads/2010/03/google-maps-mm.png"
								/>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow
									error={touched.name && errors.name}
									isInvalid={touched.name && !!errors.name}
									label="Branch Name"
								>
									<EuiFieldText
										data-testid="name-input"
										id="name"
										isInvalid={
											touched.name && !!errors.name
										}
										name="name"
										onChange={handleChange}
										placeholder="Branch Name"
										value={name}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={touched.email && errors.email}
									isInvalid={touched.email && !!errors.email}
									label="E-mail"
								>
									<EuiFieldText
										data-testid="email-input"
										disabled
										id="email"
										isInvalid={
											touched.email && !!errors.email
										}
										name="email"
										onChange={handleChange}
										placeholder="E-mail"
										value={email}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={
										touched.contactNumber &&
										errors.contactNumber
									}
									isInvalid={
										touched.contactNumber &&
										!!errors.contactNumber
									}
									label="Contact Number"
								>
									<EuiFieldText
										data-testid="contactNumber-input"
										disabled
										id="contactNumber"
										isInvalid={
											touched.contactNumber &&
											!!errors.contactNumber
										}
										name="contactNumber"
										onChange={handleChange}
										placeholder="Contact Number"
										value={contactNumber}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFlexItem>
									<EuiFormRow
										error={touched.line1 && errors.line1}
										isInvalid={
											touched.line1 && !!errors.line1
										}
										label={t('general.address.line1')}
									>
										<EuiFieldText
											data-testid="line1-input"
											id="line1"
											isInvalid={
												touched.line1 && !!errors.line1
											}
											name="line1"
											onChange={handleChange}
											placeholder={t(
												'general.address.line1',
											)}
											value={line1}
										/>
									</EuiFormRow>
									<EuiFormRow
										label={t('general.address.line2')}
									>
										<EuiFieldText
											data-testid="line2-input"
											id="line2"
											name="line2"
											onChange={handleChange}
											placeholder={t(
												'general.address.line2',
											)}
											value={line2}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={touched.city && errors.city}
										isInvalid={
											touched.city && !!errors.city
										}
										label={t('general.address.city')}
									>
										<EuiFieldText
											data-testid="city-input"
											id="city"
											isInvalid={
												touched.city && !!errors.city
											}
											name="city"
											onChange={handleChange}
											placeholder={t(
												'general.address.city',
											)}
											value={city}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={touched.postal && errors.postal}
										isInvalid={
											touched.postal && !!errors.postal
										}
										label={t('general.address.postal')}
									>
										<EuiFieldText
											data-testid="postal-input"
											id="postal"
											isInvalid={
												touched.postal &&
												!!errors.postal
											}
											name="postal"
											onChange={handleChange}
											placeholder={t(
												'general.address.postal',
											)}
											value={postal}
										/>
									</EuiFormRow>
								</EuiFlexItem>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow label="Description">
									<EuiTextArea
										data-testid="description-input"
										disabled
										id="description"
										name="description"
										onChange={handleChange}
										placeholder="Description"
										value={description}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiForm>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />
		</Page>
	);
};

export default Branch;
