import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useFormik } from 'formik';
import {
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiTitle,
	EuiText,
} from '@elastic/eui';
import _ from 'lodash';

import Page from 'components/Page';
import VendorDropdown from 'components/forms/VendorDropdown';

import { addToast } from '../dashboard/dashboard.actions';
import { updateUserDetails } from '../auth/auth.actions';
import { updateDoctor } from './doctors.fetch';

const General = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { user } = useSelector((state) => ({
		user: state.auth.user,
	}));
	const { defaultVendor } = user.details;
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [hasChanges, setHasChanges] = useState(false);

	const {
		handleSubmit,
		initialValues,
		resetForm,
		setFieldValue,
		values,
	} = useFormik({
		initialValues: {
			selectedVendor: defaultVendor ? defaultVendor.id : '',
		},
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			setIsLoading(true);
			try {
				const payload = {
					defaultVendor: data.selectedVendor,
				};
				const { data: response } = await updateDoctor(
					user.details.id,
					payload,
				);

				dispatch(
					updateUserDetails({
						...user,
						details: {
							...user.details,
							defaultVendor: response.defaultVendor,
						},
					}),
				);

				dispatch(
					addToast(
						'Doctor updated',
						'Default Pharmacy updated',
						'success',
						'check',
					),
				);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	const { selectedVendor } = values;

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}

		if (error) toastError();
	}, [error]);

	// eslint-disable-next-line no-unused-vars
	const handleCancel = () => {
		resetForm({ values: initialValues });
	};

	return (
		<Page
			headerRight={
				<EuiFlexGroup direction="row" gutterSize="m">
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							disabled={isLoading || hasChanges}
							iconType="crossInACircleFilled"
							onClick={handleCancel}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading || hasChanges}
							fill
							iconType="checkInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			}
			stickyHeader
			title={t('settings.title')}
		>
			<EuiTitle>
				<EuiText>{t('general.title')} Settings</EuiText>
			</EuiTitle>
			{/* <EuiSpacer size="m" /> */}
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="s" />
			<EuiFlexGroup direction="row" justifyContent="spaceBetween">
				<EuiFlexItem style={{ maxWidth: 1300 }}>
					<EuiForm>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiFormRow label="Prefered Pharmacy">
									<VendorDropdown
										id="selectedVendor"
										name="selectedVendor"
										onChange={(value) => {
											setFieldValue(
												'selectedVendor',
												value,
											);
										}}
										selected={selectedVendor}
									/>
								</EuiFormRow>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiForm>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiSpacer size="m" />
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />
		</Page>
	);
};

export default General;
