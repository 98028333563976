import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import {
	EuiLink,
	EuiButton,
	EuiFlexItem,
	EuiDatePicker,
	EuiFlexGroup,
} from '@elastic/eui';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import moment from 'moment';
import _ from 'lodash';

import { ROLE_PATH } from 'constants/protect';
import { DOCTOR, ADMIN, SUPER_ADMIN } from 'components/roles';
import { formatDate } from 'utils/helpers';
import Page from 'components/Page';
import Table from 'components/Table';
import ViewPrescription from './ViewPrescription';
import { getPrescriptions } from './prescriptions.fetch';

import RxStatus from './components/RxStatus';
import AddRxButton from './components/AddRxButton';
import { getRxSuccess } from './prescriptions.actions';

const Prescriptions = () => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const { prescriptions, role } = useSelector((state) => ({
		role: _.get(state, ROLE_PATH),
		prescriptions: state.prescriptions,
	}));
	// const [prescriptions, setPrescriptions] = useState([]);
	const [, setError] = useState(null);
	const [, setIsLoading] = useState(false);
	const auth = useSelector((state) => state.auth);
	const [selectedRx, setSelectedRx] = useState({
		data: null,
		visible: false,
	});
	const [columns, setColumns] = useState([]);
	const [selection, setSelection] = useState([]);
	const [date, setDate] = useState(null);
	const [filter, setFilter] = useState('');

	useEffect(() => {
		if (columns.length) return;

		const cols = [
			{
				field: 'code',
				name: 'Prescription Code',
				sortable: true,
				render: (code, data) => (
					<Link
						onClick={(e) => {
							if (role === DOCTOR.key) {
								e.preventDefault();
								setSelectedRx({ data, visible: true });

								return false;
							}

							return true;
						}}
						to={`/prescriptions/${data.id}`}
					>
						<EuiLink>{code}</EuiLink>
					</Link>
				),
			},
			{
				field: 'patient.firstName',
				name: 'Patient',
				sortable: true,
				render: (firstName, { patient: { lastName } }) =>
					`${firstName} ${lastName}`,
			},
			{
				field: 'status',
				name: t('general.status'),
				sortable: true,
				render: (status) => <RxStatus status={status} />,
			},
			{
				field: 'createdAt',
				name: 'Date',
				dataType: 'date',
				sortable: true,
				render: (createdAt) => moment(createdAt).format('ll'),
			},
		];

		if (role === ADMIN.key || role === SUPER_ADMIN.key) {
			cols.push({
				field: 'branch.name',
				name: 'Branch',
				sortable: true,
			});
		}

		if (role !== DOCTOR.key) {
			cols.push({
				field: 'pickup',
				name: 'Fulfillment',
				render: (pickup) => (pickup ? 'For Pickup' : 'Delivery'),
			});
		}

		if (role === DOCTOR.key) {
			cols.push({
				field: 'vendor.name',
				name: 'Pharmacy',
				sortable: true,
			});
		}

		cols.push({
			actions: [
				{
					name: 'View',
					description: 'View',
					onClick: async (data) => {
						setSelectedRx({ data, visible: true });
					},
					icon: 'eye',
					type: 'icon',
					isPrimary: true,
				},
			],
		});

		setColumns(cols);
	}, [columns, auth]);

	useEffect(() => {
		async function fetchPrescriptions() {
			try {
				setIsLoading(true);
				const { data } = await getPrescriptions({
					_sort: 'createdAt:DESC',
				});
				// setPrescriptions(results.data);
				dispatch(getRxSuccess(data));
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchPrescriptions();
	}, []);

	useEffect(() => {
		async function fetchPrescriptionsByDate() {
			if (date) {
				try {
					setIsLoading(false);
					const { data } = await getPrescriptions({
						createdAt_gte: moment(date).startOf('day').format(),
						createdAt_lte: moment(date).endOf('day').format(),
					});

					dispatch(getRxSuccess(data));
				} catch (err) {
					setError(err.message || 'Something went wrong');
				} finally {
					setError(null);
					setIsLoading(false);
				}
			}
		}

		fetchPrescriptionsByDate();
	}, [date]);

	const clearFilters = async () => {
		setDate(null);
		try {
			setIsLoading(true);
			const { data } = await getPrescriptions();
			setFilter('');
			dispatch(getRxSuccess(data));
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
		}
	};

	const selectionProps = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelection(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selection,
	};

	const renderToolsLeft = () => {
		if (!selection.length) {
			return null;
		}

		return (
			<EuiButton color="danger" fill={false} iconType="trash">
				Delete
			</EuiButton>
		);
	};

	const renderToolsRight = () => [
		<EuiFlexItem key="datePicker" grow={false} justifyContent="center">
			<EuiDatePicker
				onChange={(e) => setDate(formatDate(e))}
				placeholder={t('general.date')}
				value={date}
			/>
		</EuiFlexItem>,
		<EuiFlexItem key="clearFilter" grow={false} justifyContent="center">
			<EuiButton
				color="secondary"
				iconType="broom"
				onClick={clearFilters}
			>
				Clear Filters
			</EuiButton>
		</EuiFlexItem>,
	];

	const search = {
		query: filter,
		box: {
			incremental: true,
		},
		onChange: (v) => {
			setFilter(v.queryText);
		},
		toolsLeft: renderToolsLeft(),
		toolsRight: renderToolsRight(),
		filters: [
			{
				type: 'field_value_selection',
				field: 'status',
				name: 'Status',
				multiSelect: false,
				options: [
					{
						value: 'new',
						name: 'new',
						view: <RxStatus status="new" />,
					},
					{
						value: 'encoded',
						name: 'encoded',
						view: <RxStatus status="encoded" />,
					},
					{
						value: 'partial',
						name: 'partial',
						view: <RxStatus status="partial" />,
					},
					{
						value: 'served',
						name: 'served',
						view: <RxStatus status="served" />,
					},
				],
			},
		],
	};

	return (
		<>
			<Helmet title={t('prescriptions.title')} />
			<Page
				headerRight={
					<EuiFlexGroup direction="row" gutterSize="m">
						<AddRxButton />
					</EuiFlexGroup>
				}
				title={t('prescriptions.title')}
			>
				<Table
					columns={columns}
					items={prescriptions}
					search={search}
					selection={selectionProps}
				/>
				<ViewPrescription
					data={selectedRx.data}
					onClose={() => {
						setSelectedRx({ data: null, visible: false });
					}}
					visible={selectedRx.visible}
				/>
			</Page>
		</>
	);
};

export default Prescriptions;
