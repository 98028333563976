import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet-async';
import {
	EuiButton,
	EuiButtonEmpty,
	EuiCallOut,
	EuiCheckbox,
	EuiForm,
	EuiFormRow,
	EuiText,
	EuiFieldText,
	EuiFieldPassword,
	EuiSpacer,
	EuiFlexItem,
	EuiFlexGroup,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { loginAction } from './auth.actions';
import { postLogin } from './auth.fetch';

const LoginSchema = Yup.object().shape({
	identifier: Yup.string().required('Username or Email is required'),
	password: Yup.string().required('Password is required'),
	remember: Yup.bool(),
});

const emailValid = Yup.string().email();

function Login() {
	const dispatch = useDispatch();
	const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);

	// Setup Formik
	const formik = useFormik({
		initialValues: {
			identifier: '',
			password: '',
			remember: false,
		},
		validationSchema: LoginSchema,
		validateOnBlur: true,
		onSubmit: async (values) => {
			try {
				setError(null);
				setIsLoading(true);

				const isEmail = await emailValid.isValid(values.identifier);

				const data = await postLogin({
					...values,
					identifier: isEmail
						? values.identifier.toLowerCase()
						: values.identifier,
				});

				dispatch(loginAction(data));
			} catch (err) {
				// set error message from api request failure
				setError(err.message || 'Something went wrong');
			} finally {
				setIsLoading(false);
			}
		},
	});

	const navigateToForgot = () => history.push('/forgot-password');

	const navigateToRegistration = () => history.push('/register');

	const disableButton = Object.keys(formik.errors).length > 0;

	useEffect(() => {
		// eslint-disable-next-line consistent-return
		const listener = (event) => {
			if (event.code === 'Enter' || event.code === 'NumpadEnter') {
				return formik.handleSubmit();
			}
		};
		document.addEventListener('keydown', listener);
		return () => {
			document.removeEventListener('keydown', listener);
		};
	}, []);

	return (
		<>
			<Helmet title="Login" />
			<EuiText textAlign="center">
				<h3>Login</h3>
			</EuiText>
			<EuiSpacer size="s" />
			<EuiForm>
				{error && (
					<>
						<EuiCallOut color="danger" size="s" title={error} />
						<EuiSpacer size="s" />
					</>
				)}
				<EuiFormRow
					error={formik.errors.identifier}
					isInvalid={!!formik.errors.identifier}
					label="Enter Username or E-mail"
				>
					<EuiFieldText
						data-testid="identifier-input"
						icon="user"
						id="identifier"
						isInvalid={!!formik.errors.identifier}
						name="identifier"
						onChange={formik.handleChange}
						placeholder="Username or E-mail"
						value={formik.values.identifier}
					/>
				</EuiFormRow>
				<EuiFormRow
					error={formik.errors.password}
					isInvalid={!!formik.errors.password}
					label="Enter Password"
				>
					<EuiFieldPassword
						data-testid="password-input"
						id="password"
						isInvalid={!!formik.errors.password}
						name="password"
						onChange={formik.handleChange}
						placeholder="Password"
					/>
				</EuiFormRow>
				<EuiFormRow>
					<EuiCheckbox
						checked={formik.values.remember}
						id="remember"
						label="Remember Me"
						name="remember"
						onChange={formik.handleChange}
					/>
				</EuiFormRow>
				<EuiButton
					color="primary"
					data-testid="login-button"
					disabled={disableButton}
					fill
					fullWidth
					isLoading={isLoading}
					onClick={formik.handleSubmit}
					type="submit"
				>
					Login
				</EuiButton>
				<EuiSpacer size="s" />
				<EuiFlexGroup justifyContent="center">
					<EuiFlexItem grow={false}>
						<EuiButtonEmpty
							flush="left"
							onClick={navigateToForgot}
							size="xs"
						>
							Forgot Password?
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem grow={false}>
						<EuiButtonEmpty
							flush="left"
							onClick={navigateToRegistration}
							size="xs"
						>
							Register
						</EuiButtonEmpty>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiForm>
		</>
	);
}

export default Login;
