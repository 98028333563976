// eslint-disable-next-line import/prefer-default-export
import api from '../../utils/api';

export const createBranch = async (payload) => api.post('/branches', payload);

export const getBranches = async () => api.get('/branches');

/**
 * Get Branches by Vendor
 * @param {String} vendor Vendor ID
 * @param {Boolean} pickup set to true if pickup only
 */
export const getBranchesByVendor = async (vendor, pickup) => {
	const params = {
		vendor,
	};

	if (pickup) {
		params.pickup = true;
	}

	return api.get(`/branches`, { params });
};

export const getBranch = async (id) => api.get(`/branches/${id}`);

export const updateBranch = async (id, payload) =>
	api.put(`/branches/${id}`, payload);
