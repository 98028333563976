import React from 'react';
import {
	EuiPage,
	EuiPageHeader,
	EuiPageBody,
	EuiPageHeaderSection,
	EuiTitle,
	EuiPageContent,
	EuiPageContentHeader,
	EuiPageContentHeaderSection,
	EuiPageContentBody,
	EuiFlexGroup,
	EuiFlexItem,
} from '@elastic/eui';

import PropTypes from 'prop-types';

const Page = ({
	children,
	contentTitle,
	headerRight,
	largeSidebar,
	sidebar,
	stickyHeader,
	title,
	titleSize,
}) => (
	<EuiPage className={stickyHeader ? 'hasSticky' : null}>
		<EuiPageBody>
			<EuiPageHeader className={stickyHeader ? 'sticky' : ''}>
				{title && (
					<EuiPageHeaderSection>
						<EuiTitle size={titleSize}>
							<h1>{title}</h1>
						</EuiTitle>
					</EuiPageHeaderSection>
				)}
				{headerRight && (
					<EuiPageHeaderSection>{headerRight}</EuiPageHeaderSection>
				)}
			</EuiPageHeader>
			<EuiFlexGroup>
				{sidebar && (
					<EuiFlexItem
						className={largeSidebar ? 'rx-sideBar' : 'sideBar'}
						grow={false}
					>
						<EuiPageContent>{sidebar}</EuiPageContent>
					</EuiFlexItem>
				)}
				<EuiFlexItem>
					<EuiPageContent>
						{contentTitle && (
							<EuiPageContentHeader>
								<EuiPageContentHeaderSection>
									<EuiTitle>
										<h2>{contentTitle}</h2>
									</EuiTitle>
								</EuiPageContentHeaderSection>
							</EuiPageContentHeader>
						)}
						<EuiPageContentBody>{children}</EuiPageContentBody>
					</EuiPageContent>
				</EuiFlexItem>
			</EuiFlexGroup>
		</EuiPageBody>
	</EuiPage>
);

Page.propTypes = {
	children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	contentTitle: PropTypes.string,
	headerRight: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	largeSidebar: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	sidebar: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
	stickyHeader: PropTypes.bool,
	title: PropTypes.string,
	titleSize: PropTypes.oneOf(['xs', 's', 'm', 'l', 'xl']),
};

Page.defaultProps = {
	children: null,
	contentTitle: null,
	headerRight: null,
	largeSidebar: null,
	sidebar: null,
	stickyHeader: false,
	title: null,
	titleSize: 'm',
};

export default Page;
