import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { FaFilePdf } from 'react-icons/fa';
import {
	EuiFlyout,
	EuiFlyoutHeader,
	EuiText,
	EuiFlyoutBody,
	EuiForm,
	EuiFormRow,
	EuiFilePicker,
	EuiSelect,
	EuiImage,
	EuiFlexGrid,
	EuiFlexItem,
	EuiButton,
	EuiPanel,
	EuiSwitch,
	EuiButtonEmpty,
	EuiFlyoutFooter,
	EuiFlexGroup,
} from '@elastic/eui';

import _ from 'lodash';
import { useFormik } from 'formik';
import * as yup from 'yup';

import PropTypes from 'prop-types';

import VendorDropdown from 'components/forms/VendorDropdown';
import BranchDropdown from 'components/forms/BranchDropdown';
import PatientSearch from 'components/forms/PatientSearch';
import { addToast } from 'modules/dashboard/dashboard.actions';
import { buildLocalAddress } from 'utils/helpers';

import { createPrescription } from './prescriptions.fetch';
import {
	createRxSuccess,
	createRxDashboardSuccess,
} from './prescriptions.actions';
import NewPatientForm from './components/NewPatientForm';

const AddPrescriptionSchema = yup.object().shape({
	addNewPatient: yup.boolean().default(false),
	patient: yup.string().when('addNewPatient', {
		is: (addNew) => addNew === false,
		then: yup.string().required('Patient is required'),
	}),
	firstName: yup.string().when('addNewPatient', {
		is: (addNew) => addNew === true,
		then: yup.string().required('First Name is required'),
	}),
	lastName: yup.string().when('addNewPatient', {
		is: (addNew) => addNew === true,
		then: yup.string().required('Last Name is required'),
	}),
	contactNumber: yup.string().when('addNewPatient', {
		is: (addNew) => addNew === true,
		then: yup.string().required('Contact Number is required'),
	}),
	type: yup.string().oneOf(['rx', 's2']).default('rx').required(),
	vendor: yup.string().required('Vendor is required'),
	branch: yup.string().when('pickup', {
		is: (pickup) => pickup === true,
		then: yup.string().required('Branch is required'),
	}),
	attachments: yup
		.array()
		.of(yup.string())
		.min(1, 'We require at least 1 attachment'),
	line1: yup.string().when('addNewPatient', {
		is: (addNew) => addNew === true,
		then: yup.string().required('Address Line 1 is required'),
	}),
	cityId: yup.string().when('addNewPatient', {
		is: (addNew) => addNew === true,
		then: yup.string().required('City is required'),
	}),
	brgyId: yup.string().when('addNewPatient', {
		is: (addNew) => addNew === true,
		then: yup.string().required('Barangay is required'),
	}),
});

const AddPrescription = ({ onClose, visible }) => {
	const { auth } = useSelector((state) => ({ auth: state.auth }));
	const { details } = auth.user;
	const dispatch = useDispatch();
	const { t } = useTranslation();
	const [attachments, setAttachments] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [patient, setPatient] = useState(null);
	const defaultVendor = _.get(details, 'defaultVendor.id');

	const formikBag = useFormik({
		initialValues: {
			addNewPatient: false,
			patient: '',
			firstName: '',
			lastName: '',
			contactNumber: '',
			line1: '',
			line2: '',
			city: '',
			cityId: '',
			brgy: '',
			brgyId: '',
			type: 'rx',
			attachments: [],
			vendor: _.get(details, 'defaultVendor.id', ''),
			branch: '',
			pickup: false,
		},
		validationSchema: AddPrescriptionSchema,
		onSubmit: async (data, { resetForm: reset }) => {
			try {
				const payload = {
					attachments,
					pickup: data.pickup,
					type: data.type,
					vendor: data.vendor,
				};

				if (payload.pickup) {
					payload.branch = data.branch;
				}

				if (data.addNewPatient) {
					payload.patient = {
						firstName: data.firstName,
						lastName: data.lastName,
						contactDetails: [
							{ type: 'mobile', value: data.contactNumber },
						],
						address: {
							brgy: data.brgy,
							brgyId: data.brgyId,
							cityId: data.cityId,
							city: data.city,
							line1: data.line1,
							line2: data.line2,
						},
					};
				} else {
					payload.patient = data.patient;
				}

				setIsLoading(true);

				const { data: newPrescription } = await createPrescription(
					payload,
				);

				if (!newPrescription) {
					throw new Error('Failed to create prescription');
				}

				dispatch(
					addToast(
						'Success',
						`Successfully added prescription with code: ${newPrescription.code}`,
						'success',
					),
				);
				dispatch(createRxSuccess(newPrescription));
				dispatch(createRxDashboardSuccess(newPrescription));

				setAttachments([]);
				reset();
				onClose();
			} catch (err) {
				dispatch(addToast('Error', err.message, 'error'));
			} finally {
				setIsLoading(false);
			}
		},
	});

	const {
		errors,
		handleChange,
		handleSubmit,
		resetForm,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	const renderFiles = (files) => {
		if (!files.length) return null;

		return files.map((file) => {
			const url = URL.createObjectURL(file);
			const isPdf = file.type === 'application/pdf';
			return (
				<EuiFlexItem key={file.name}>
					<EuiPanel
						style={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{isPdf ? (
							<FaFilePdf size="60" />
						) : (
							<EuiImage
								allowFullScreen={
									!file.type === 'application/pdf'
								}
								size="s"
								url={url}
							/>
						)}
					</EuiPanel>
				</EuiFlexItem>
			);
		});
	};

	const handleFilePicker = (event) => {
		const { length, ...fileItems } = event;
		if (!length) return setAttachments([]);

		const files = Object.keys(fileItems).map((key) => event[key]);
		setFieldValue(
			'attachments',
			files.map((file) => file.name),
		);

		return setAttachments(files);
	};

	const handleClose = () => {
		onClose();
		resetForm();
	};

	if (!visible) return null;

	const rxTypes = [
		{
			value: 'rx',
			text: 'RX',
		},
	];

	if (_.get(auth, 'user.details.s2Validated', false)) {
		rxTypes.push({
			value: 's2',
			text: 'S2',
		});
	}

	const onPatientSelect = (selected) => {
		if (selected) {
			setPatient(selected);
			return setFieldValue('patient', selected.value);
		}

		setPatient('');
		return setFieldValue('patient', '');
	};

	let localAddress = '';

	if (patient && patient.address) {
		localAddress = buildLocalAddress(patient.address);
	} else {
		localAddress = buildLocalAddress(values);
	}

	return (
		<EuiFlyout
			closeButtonAriaLabel="Close Add Prescription"
			onClose={handleClose}
			size="s"
		>
			<EuiFlyoutHeader hasBorder>
				<EuiText>
					<h3>Add Prescription</h3>
				</EuiText>
			</EuiFlyoutHeader>
			<EuiFlyoutBody>
				<EuiForm>
					<EuiFormRow>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiSwitch
									checked={values.addNewPatient}
									id="addNewPatient"
									label="New Patient"
									name="addNewPatient"
									onChange={() => {
										setFieldValue(
											'addNewPatient',
											!values.addNewPatient,
										);
									}}
								/>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiSwitch
									checked={values.pickup}
									id="pickup"
									label="For Pick-Up"
									name="pickup"
									onChange={() => {
										setFieldValue('pickup', !values.pickup);
									}}
								/>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFormRow>
					{values.addNewPatient ? (
						<NewPatientForm formikBag={formikBag} />
					) : (
						<EuiFormRow
							error={touched.patient && errors.patient}
							isInvalid={touched.patient && !!errors.patient}
							label="Patient"
						>
							<PatientSearch
								onChange={onPatientSelect}
								selected={patient}
							/>
						</EuiFormRow>
					)}
					{rxTypes.length > 1 ? (
						<EuiFormRow
							error={touched.type && errors.type}
							isInvalid={touched.type && !!errors.type}
							label="Prescription Type"
						>
							<EuiSelect
								id="type"
								isInvalid={touched.type && !!errors.type}
								name="type"
								onChange={handleChange}
								options={rxTypes}
								value={values.type}
							/>
						</EuiFormRow>
					) : null}
					{localAddress ? (
						<EuiFormRow
							error={touched.vendor && errors.vendor}
							isInvalid={touched.vendor && !!errors.vendor}
							label="Pharmacy"
						>
							<VendorDropdown
								address={localAddress}
								id="vendor"
								isInvalid={touched.vendor && !!errors.vendor}
								name="vendor"
								onChange={(value) => {
									if (value) {
										return setFieldValue('vendor', value);
									}

									return setFieldValue('vendor', null);
								}}
								preferred={defaultVendor}
								selected={values.vendor}
							/>
						</EuiFormRow>
					) : null}
					{localAddress && values.pickup && values.vendor ? (
						<EuiFormRow
							error={touched.branch && errors.branch}
							isInvalid={touched.branch && !!errors.branch}
							label="Select Branch"
						>
							<BranchDropdown
								onChange={(selectedOption) => {
									if (selectedOption) {
										return setFieldValue(
											'branch',
											selectedOption.value,
										);
									}

									return setFieldValue('branch', null);
								}}
								pickupOnly
								vendor={values.vendor}
							/>
						</EuiFormRow>
					) : null}
					<EuiFormRow
						error={touched.attachments && errors.attachments}
						isInvalid={touched.attachments && !!errors.attachments}
						label="Attachments"
					>
						<EuiFilePicker
							isInvalid={
								touched.attachments && !!errors.attachments
							}
							multiple
							onChange={handleFilePicker}
						/>
					</EuiFormRow>
					<EuiFormRow>
						<EuiFlexGrid columns={2} gutterSize="s">
							{renderFiles(attachments)}
						</EuiFlexGrid>
					</EuiFormRow>
				</EuiForm>
			</EuiFlyoutBody>
			<EuiFlyoutFooter>
				<EuiFlexGroup
					direction="row"
					gutterSize="m"
					justifyContent="spaceBetween"
				>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							iconType="crossInACircleFilled"
							onClick={handleClose}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading}
							fill
							iconType="plusInCircleFilled"
							loading={isLoading}
							onClick={handleSubmit}
						>
							Add Prescription
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiFlyoutFooter>
		</EuiFlyout>
	);
};

AddPrescription.propTypes = {
	onClose: PropTypes.func.isRequired,
	visible: PropTypes.bool,
};

AddPrescription.defaultProps = {
	visible: false,
};

export default AddPrescription;
