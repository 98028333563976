import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
	EuiForm,
	EuiFlexGroup,
	EuiFlexItem,
	EuiFormRow,
	EuiToolTip,
	EuiFieldText,
	EuiSpacer,
	EuiButton,
	EuiLink,
	EuiCheckbox,
	// EuiCallOut,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import TermsModal from './TermsModal';
import PrivacyModal from './PrivacyModal';

const RegistrationForm = ({ errors, formik, isLoading }) => {
	const { t } = useTranslation();
	const [termOpen, setTermOpen] = useState(false);
	const [privacyOpen, setPrivacyOpen] = useState(false);
	// payload errors
	const [serverErrors, setServerErrors] = useState([]);
	// general server error
	// const [error, setError] = useState(null);
	const { errors: formikErrors, handleChange, handleSubmit, values } = formik;

	useEffect(() => {
		if (typeof errors === 'object' && Object.keys(errors) > 0) {
			const errs = [];
			Object.keys(errors).forEach((key) => errs.push(errors[key]));
			setServerErrors(errs);
		} else {
			// setError('Registration Failed');
		}
	}, [errors]);

	const disableButton =
		Object.keys(formikErrors).length > 0 || !values.acceptTerms;
	const toggleTerm = () => setTermOpen(!termOpen);
	const togglePrivacy = () => setPrivacyOpen(!privacyOpen);

	const hasServerErrors = serverErrors.length > 0;

	return (
		<>
			<EuiForm error={serverErrors} isInvalid={hasServerErrors}>
				<EuiFlexGroup>
					<EuiFlexItem>
						<EuiFormRow
							display="columnCompressed"
							error={formikErrors.firstName}
							isInvalid={!!formikErrors.firstName}
							label="First Name"
						>
							<EuiToolTip
								content={t('register.nameTip')}
								position="right"
							>
								<EuiFieldText
									data-testid="firstName-input"
									id="firstName"
									isInvalid={!!formikErrors.firstName}
									name="firstName"
									onChange={handleChange}
									placeholder="First Name"
									value={values.firstName}
								/>
							</EuiToolTip>
						</EuiFormRow>
						<EuiFormRow
							display="columnCompressed"
							error={formikErrors.middleName}
							isInvalid={!!formikErrors.middleName}
							label="Middle Name"
						>
							<EuiFieldText
								data-testid="middleName-input"
								id="middleName"
								isInvalid={!!formikErrors.middleName}
								name="middleName"
								onChange={handleChange}
								placeholder="Middle Name"
								value={values.middleName}
							/>
						</EuiFormRow>
						<EuiFormRow
							display="columnCompressed"
							error={formikErrors.lastName}
							isInvalid={!!formikErrors.lastName}
							label="Last Name"
						>
							<EuiFieldText
								data-testid="lastName-input"
								id="lastName"
								isInvalid={!!formikErrors.lastName}
								name="lastName"
								onChange={handleChange}
								placeholder="Last Name"
								value={values.lastName}
							/>
						</EuiFormRow>
						<EuiFormRow
							display="columnCompressed"
							error={formikErrors.email}
							isInvalid={!!formikErrors.email}
							label={t('register.email')}
						>
							<EuiToolTip
								content={t('register.emailTip')}
								position="right"
							>
								<EuiFieldText
									data-testid="email-input"
									id="email"
									isInvalid={!!formikErrors.email}
									name="email"
									onChange={handleChange}
									placeholder={t('register.email')}
									value={values.email}
								/>
							</EuiToolTip>
						</EuiFormRow>
						<EuiFormRow
							display="columnCompressed"
							error={formikErrors.username}
							isInvalid={!!formikErrors.username}
							label={t('register.username')}
						>
							<EuiFieldText
								data-testid="username-input"
								id="username"
								isInvalid={!!formikErrors.username}
								name="username"
								onChange={handleChange}
								placeholder={t('register.username')}
								value={values.username}
							/>
						</EuiFormRow>
						<EuiFormRow
							display="columnCompressed"
							error={formikErrors.password}
							isInvalid={!!formikErrors.password}
							label={t('register.password')}
						>
							<EuiFieldText
								data-testid="password-input"
								id="password"
								isInvalid={!!formikErrors.password}
								name="password"
								onChange={handleChange}
								placeholder={t('register.password')}
								type="password"
								value={values.password}
							/>
						</EuiFormRow>
						<EuiFormRow
							display="columnCompressed"
							error={formikErrors.confirmPassword}
							isInvalid={!!formikErrors.confirmPassword}
							label={t('register.confirmPassword')}
						>
							<EuiFieldText
								data-testid="confirmPassword-input"
								id="confirmPassword"
								isInvalid={!!formikErrors.confirmPassword}
								name="confirmPassword"
								onChange={handleChange}
								placeholder={t('register.confirmPassword')}
								type="password"
								value={values.confirmPassword}
							/>
						</EuiFormRow>
						<EuiFormRow display="columnCompressedSwitch" label=" ">
							<EuiCheckbox
								checked={values.acceptTerms}
								id="acceptTerms"
								label={
									<span>
										I confirm that I have read and agree to
										the{' '}
										<EuiLink
											color="ghost"
											onClick={toggleTerm}
										>
											Terms & Conditions
										</EuiLink>{' '}
										and{' '}
										<EuiLink
											color="ghost"
											onClick={togglePrivacy}
										>
											Privacy Policy
										</EuiLink>
									</span>
								}
								name="acceptTerms"
								onChange={handleChange}
							/>
						</EuiFormRow>
					</EuiFlexItem>
				</EuiFlexGroup>
				<EuiSpacer size="l" />
				<EuiButton
					color="primary"
					data-testid="registration-button"
					disabled={disableButton}
					fill
					fullWidth
					isLoading={isLoading}
					onClick={handleSubmit}
					type="submit"
				>
					{t('register.titleButton')}
				</EuiButton>
			</EuiForm>
			<TermsModal isOpen={termOpen} onClose={toggleTerm} />
			<PrivacyModal isOpen={privacyOpen} onClose={togglePrivacy} />
		</>
	);
};

RegistrationForm.propTypes = {
	errors: PropTypes.shape(),
	formik: PropTypes.shape({
		errors: PropTypes.shape(),
		values: PropTypes.shape(),
		handleChange: PropTypes.func,
		handleSubmit: PropTypes.func,
	}).isRequired,
	isLoading: PropTypes.bool,
};

RegistrationForm.defaultProps = {
	errors: null,
	isLoading: false,
};

export default RegistrationForm;
