/* eslint-disable import/prefer-default-export */
import React from 'react';
import { FaFilePrescription } from 'react-icons/fa';
import {
	FiBox,
	FiHome,
	FiPackage,
	FiUsers,
	FiUser,
	FiSettings,
} from 'react-icons/fi';

import Dashboard from 'modules/dashboard/Dashboard';
import Order from 'modules/orders/Order';
import Orders from 'modules/orders/Orders';
import AddOrder from 'modules/orders/AddOrder';
import Product from 'modules/products/Product';
import Products from 'modules/products/Products';
import Prescriptions from 'modules/prescriptions/Prescriptions';
import Prescription from 'modules/prescriptions/Prescription';
import Users from 'modules/users/Users';
import User from 'modules/users/User';
import Patients from 'modules/patients/Patients';
import Patient from 'modules/patients/Patient';
import Settings from 'modules/settings/Settings';
import General from 'modules/doctors/General';
import Branch from 'modules/branches/Branch';

import { SUPER_ADMIN, DOCTOR, PHARMACIST, ADMIN } from './roles';

export const menu = (t) => [
	{
		component: Dashboard,
		exact: true,
		path: '/',
		name: t('dashboard.title').toLowerCase(),
		icon: () => <FiHome size={24} />,
		title: t('dashboard.title'),
		users: [SUPER_ADMIN.key, DOCTOR.key, PHARMACIST.key, ADMIN.key],
	},
	{
		component: Prescriptions,
		path: '/prescriptions',
		inMenu: true,
		exact: true,
		icon: () => <FaFilePrescription size={24} />,
		name: t('prescriptions.title').toLowerCase(),
		title: t('prescriptions.title'),
		users: [SUPER_ADMIN.key, DOCTOR.key, PHARMACIST.key, ADMIN.key],
	},
	{
		component: Prescription,
		path: '/prescriptions/:prescriptionId',
		inMenu: false,
		users: [SUPER_ADMIN.key, DOCTOR.key, PHARMACIST.key, ADMIN.key],
	},
	{
		component: Orders,
		path: '/orders',
		exact: true,
		name: t('orders.title').toLowerCase(),
		icon: () => <FiPackage size={24} />,
		title: t('orders.title'),
		users: [SUPER_ADMIN.key, PHARMACIST.key, ADMIN.key],
	},
	{
		component: Order,
		path: '/orders/:orderId',
		inMenu: false,
		users: [SUPER_ADMIN.key, PHARMACIST.key, ADMIN.key],
	},
	{
		component: AddOrder,
		path: '/orders/create',
		inMenu: false,
		users: [SUPER_ADMIN.key, PHARMACIST.key, ADMIN.key],
	},
	{
		component: Products,
		path: '/products',
		exact: true,
		name: t('products.title').toLowerCase(),
		icon: () => <FiBox size={24} />,
		title: t('products.title'),
		users: [SUPER_ADMIN.key],
	},
	{
		component: Product,
		path: '/products/:productId',
		inMenu: false,
		users: [SUPER_ADMIN.key],
	},
	{
		component: Users,
		path: '/users',
		exact: true,
		name: t('users.title').toLowerCase(),
		icon: () => <FiUser size={24} />,
		title: t('users.title'),
		users: [SUPER_ADMIN.key],
	},
	{
		component: User,
		path: '/users/:userId',
		inMenu: false,
		users: [SUPER_ADMIN.key],
	},
	{
		component: Patients,
		path: '/patients',
		exact: true,
		name: t('patients.title').toLowerCase(),
		icon: () => <FiUsers size={24} />,
		title: t('patients.title'),
		users: [DOCTOR.key],
	},
	{
		component: Patient,
		path: '/patients/:patientId',
		inMenu: false,
		users: [DOCTOR.key],
	},
	{
		component: Settings,
		path: '/settings',
		exact: true,
		name: t('settings.title').toLowerCase(),
		icon: () => <FiSettings size={24} />,
		title: t('settings.title'),
		users: [SUPER_ADMIN.key],
	},
	{
		component: General,
		path: '/settings',
		exact: true,
		name: t('settings.title').toLowerCase(),
		icon: () => <FiSettings size={24} />,
		title: t('settings.title'),
		users: [DOCTOR.key],
	},
	{
		component: Branch,
		path: '/branches/:branchId',
		inMenu: false,
		users: [SUPER_ADMIN.key],
	},
];
