import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import numeral from 'numeral';
import Page from 'components/Page';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
	EuiText,
	EuiTitle,
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiSuperSelect,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiTextArea,
	EuiFieldNumber,
	EuiDescriptionList,
	EuiLink,
	EuiLoadingSpinner,
} from '@elastic/eui';
import _ from 'lodash';

import Table from 'components/Table';
import ConfirmationModal from 'components/ConfirmationModal';
import DropdownOptWSub from 'components/DropdownOptWSub';
import ContactDetails from 'modules/_global/ContactDetails';
import OrderStatus from './components/OrderStatus';
import LineItemModal from './components/LineItemModal';
import { addToast } from '../dashboard/dashboard.actions';
import {
	getOrder,
	updateOrder,
	updateOrderStatus,
	// getCouriers,
} from './orders.fetch';
import PrintOrder from './PrintOrder';

const OrderSchema = Yup.object().shape({
	discountValue: Yup.number()
		.default(0)
		.min(0, 'Discount must be greater than or equal to 0')
		.when('discountType', {
			is: (value) => value && value === 'percent',
			then: Yup.number().max(100, 'Percent discount value is 0-100'),
		}),
});

const Order = () => {
	const { t } = useTranslation();
	const { orderId } = useParams();
	const dispatch = useDispatch();
	const { auth } = useSelector((state) => ({
		auth: state.auth,
	}));
	const [isLoading, setIsLoading] = useState(true);
	const [selected, setSelected] = useState([]);
	const [error, setError] = useState(null);
	const [order, setOrder] = useState({
		shippingRecord: {
			courier: '',
			deliveryDate: '',
			pickupDate: '',
		},
	});
	const [hasChanges, setHasChanges] = useState(false);

	const [selectedItem, setSelectedItem] = useState(null);
	const { patient } = order;
	const [isLineModalVisible, setIsLineModalVisible] = useState(false);
	const [
		isConfirmDeleteModalVisisble,
		setIsConfirmDeleteModalVisible,
	] = useState(false);
	const [
		isConfirmCancelChangesModalVisible,
		setIsConfirmCancelChangesModalVisible,
	] = useState(false);
	const priceEnabled = _.get(auth, 'user.vendor.priceEnabled');

	// const [status, setStatus] = useState(order.status);
	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			id: orderId,
			status: order.status,
			patient: order.patient,
			branch: order.branch,
			vendor: order.vendor,
			discountType: order.discountType || 'percent',
			discountValue: order.discountValue,
			notes: order.notes,
			lineItemsSubtotal: order.lineItemsSubtotal,
			discountSubtotal: order.discountSubtotal,
			vatAmount: order.vatAmount,
			totalAmount: order.totalAmount,
			lineItems: order.lineItems || [],
			prescription: order.prescription,
			address: _.get(order, 'patient.address', {}),
			pickup: order.pickup || false,
		},
		validationSchema: OrderSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		onSubmit: async (data) => {
			// eslint-disable-next-line no-unused-vars
			let payload = {
				patient: patient.id,
				discountType: data.discountType,
				discountValue: data.discountValue,
				lineItems: data.lineItems || [],
				notes: data.notes,
			};

			if (data.shippingRecord) {
				payload = {
					...payload,
					shippingRecord: {
						id: order.shippingRecord.id,
						deliveryDate: data.deliveryDate,
						pickupDate: data.pickupDate,
						courier: data.courier,
					},
				};
			}

			try {
				setIsLoading(true);
				const { data: result } = await updateOrder(orderId, payload);

				if (!result) {
					throw new Error('Update Failed');
				}

				const { data: updatedOrder } = await getOrder(orderId);

				setOrder(updatedOrder);

				dispatch(addToast('Order Updated', null, 'success', 'check'));
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	const {
		address,
		branch,
		discountSubtotal,
		discountType,
		discountValue,
		lineItems,
		lineItemsSubtotal,
		notes,
		pickup,
		prescription,
		status,
		totalAmount,
		vatAmount,
	} = values;

	useEffect(() => {
		async function getOrderRecord() {
			try {
				const result = await getOrder(orderId);
				setOrder(result.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}

		getOrderRecord();
	}, [orderId]);

	useEffect(() => {
		async function computeValues() {
			if (lineItems && lineItems.length > -1) {
				const subtotal = lineItems.reduce(
					(a, b) => a + (b.price * b.quantity || 0),
					0,
				);
				const vat = 0.12;
				let discount = 0;
				let payment = 0;

				// eslint-disable-next-line default-case
				switch (discountType) {
					case 'percent':
						if (!(discountValue > 100)) {
							discount = subtotal * (discountValue / 100);
						}
						break;
					case 'fixed':
						if (!(discountValue > subtotal)) {
							discount = discountValue;
						}
						break;
				}

				if (!subtotal - discount < 0) {
					payment = subtotal - discount;
				} else {
					payment = subtotal;
				}

				setFieldValue('vatAmount', payment * vat);
				setFieldValue('discountSubtotal', discount);
				setFieldValue('lineItemsSubtotal', subtotal);
				setFieldValue('totalAmount', payment);
			}
		}
		computeValues();
	}, [discountValue, discountType, lineItems]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	const addLineItem = (data) => {
		const updatedLineItems = [...lineItems, data];
		setFieldValue('lineItems', updatedLineItems);
	};

	const editLineItem = (data) => {
		setSelectedItem(data);
		setIsLineModalVisible(true);
	};

	const updateLineItem = (data) => {
		const index = lineItems.findIndex((item) => item.id === data.id);
		const itemsStart = lineItems.slice(0, index);
		const itemsEnd = lineItems.slice(index + 1);

		setFieldValue('lineItems', [...itemsStart, data, ...itemsEnd]);
	};

	const removeLineItem = (data) => {
		const index = lineItems.findIndex((item) => item.id === data.id);
		const lineItemsStart = lineItems.slice(0, index);
		const lineItemsEnd = lineItems.slice(index + 1);
		setFieldValue('lineItems', [...lineItemsStart, ...lineItemsEnd]);
	};

	const handleDelete = (data) => {
		setSelectedItem(data);
		setIsConfirmDeleteModalVisible(true);
	};

	const updateStatus = async (data, updatedStatus) => {
		setIsLoading(true);
		try {
			const result = await updateOrderStatus(orderId, updatedStatus);
			setOrder(result.data);
			dispatch(
				addToast('Success', 'Order status updated', 'success', 'check'),
			);
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setIsLoading(false);
			setError(null);
		}
	};

	const handleCancel = () => {
		setIsConfirmCancelChangesModalVisible(true);
	};

	let columns = [
		{ field: 'itemName', name: t('orders.itemName'), sortable: true },
		{
			field: 'brand',
			name: t('orders.brand'),
			sortable: true,
		},
		{
			field: 'description',
			name: t('orders.description'),
			sortable: true,
		},
		{
			field: 'quantity',
			name: t('orders.quantity'),
			sortable: true,
			dataType: 'number',
			width: '10%',
		},
	];

	if (priceEnabled) {
		columns = [
			...columns,
			{
				field: 'sku',
				name: t('orders.sku'),
				sortable: true,
				width: '10%',
			},
			{
				field: 'price',
				name: t('orders.price'),
				sortable: true,
				dataType: 'number',
				width: '10%',
				render: (price) => (
					<EuiText>{`P ${numeral(price).format('0,0.00')}`}</EuiText>
				),
			},
			{
				name: t('orders.subtotal'),
				// eslint-disable-next-line react/prop-types
				render: ({ price, quantity }) => {
					return (
						<EuiText>
							{`P ${numeral(quantity * price).format('0,0.00')}`}
						</EuiText>
					);
				},
				dataType: 'number',
				width: '10%',
			},
		];
	}

	if (status === 'open') {
		columns = [
			...columns,
			{
				width: '10%',
				actions: [
					{
						name: 'Edit',
						description: 'Edit',
						onClick: editLineItem,
						icon: 'pencil',
						type: 'icon',
						color: 'primary',
						isPrimary: true,
					},
					{
						name: 'Delete',
						description: 'Delete',
						onClick: handleDelete,
						icon: 'trash',
						type: 'icon',
						color: 'danger',
						isPrimary: true,
					},
				],
			},
		];
	}

	const discountOpts = [
		{
			value: 'percent',
			inputDisplay: t('orders.percent'),
			dropdownDisplay: (
				<DropdownOptWSub
					description={t('orders.percentSample')}
					title={t('orders.percent')}
				/>
			),
		},
		{
			value: 'fixed',
			inputDisplay: 'Fixed Amount',
			dropdownDisplay: (
				<DropdownOptWSub
					description={t('orders.amountSample')}
					title={t('orders.amount')}
				/>
			),
		},
	];

	const confirmDeleteModal = isConfirmDeleteModalVisisble ? (
		<ConfirmationModal
			message="Are you sure you want to do this?"
			onClose={() => {
				setIsConfirmDeleteModalVisible(false);
				setSelectedItem(null);
			}}
			onConfirm={() => {
				removeLineItem(selectedItem);
				setIsConfirmDeleteModalVisible(false);
				setSelectedItem(null);
			}}
			title={t('deleteLineItem.title')}
		/>
	) : null;

	const confirmCancelChangesModal = isConfirmCancelChangesModalVisible ? (
		<ConfirmationModal
			message={t('general.cancelChanges')}
			onClose={() => {
				setIsConfirmCancelChangesModalVisible(false);
			}}
			onConfirm={() => {
				resetForm({ values: initialValues });
				setIsConfirmCancelChangesModalVisible(false);
			}}
			title={t('general.confirm')}
		/>
	) : null;

	const lineModal = isLineModalVisible ? (
		<LineItemModal
			addLineItem={addLineItem}
			onClose={() => {
				setIsLineModalVisible(false);
				setSelectedItem(null);
			}}
			selectedItem={selectedItem}
			updateLineItem={updateLineItem}
		/>
	) : null;

	const selection = {
		selectable: () => true,
		onSelectionChange: (select) => {
			setSelected(select);
		},
		selectableMessage: () => undefined,
		initialSelected: selected,
	};

	// order status is 'open'
	const isOpen = status === 'open';
	// order status is confirmed by patient
	const isConfirmed = status === 'confirmed';
	// order is completed
	const isCompleted = status === 'completed';
	// order status is 'cancelled'
	const isCancelled = status === 'cancelled';

	const disableButton = Object.keys(errors).length > 0;

	let orderDetails = [];

	if (patient) {
		orderDetails = [
			{
				title: t('patients.patientName'),
				description: patient
					? `${patient.firstName} ${patient.lastName}`
					: '',
			},
			{
				title: t('patients.contactDetails'),
				description: <ContactDetails ids={patient.contactDetails} />,
			},
			{
				title: 'Order Status',
				description: <OrderStatus status={status} />,
			},
			{
				title: 'Prescription',
				description: prescription ? (
					<Link to={`/prescriptions/${prescription.id}`}>
						<EuiLink>
							{prescription ? prescription.code : ''}
						</EuiLink>
					</Link>
				) : (
					<EuiLoadingSpinner />
				),
			},
		];
	}

	if (pickup && branch) {
		orderDetails.push({
			title: 'Pick Up',
			description: branch.name,
		});
	}

	if (!pickup) {
		orderDetails.push({
			title: t('shipping.shippingAddress'),
			description: address.localAddress,
		});
	}

	let displayAddress = '';

	if (address) {
		if (address.localAddress) displayAddress = address.localAddress;

		if (!address.localAddress) {
			displayAddress = `${_.get(address, 'line1', '')}`;
			displayAddress += ` ${_.get(address, 'line2', '')}`;
			displayAddress += `, ${_.get(address, 'city', '')}`;
		}
	}

	return (
		<Page
			headerRight={
				<EuiFlexGroup direction="row" gutterSize="m">
					<EuiFlexItem>
						{order && patient && (
							<PrintOrder
								address={displayAddress}
								contactDetails={patient.contactDetails}
								lineItems={lineItems}
								name={`${patient.firstName} ${patient.lastName}`}
								orderId={order.orderId}
								prescriptionCode={
									prescription && prescription.code
								}
							/>
						)}
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							disabled={isLoading || hasChanges}
							iconType="crossInACircleFilled"
							onClick={handleCancel}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading || hasChanges || disableButton}
							fill
							iconType="checkInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			}
			sidebar={
				<EuiFlexGroup direction="column">
					{patient && (
						<EuiFlexItem>
							<EuiDescriptionList listItems={orderDetails} />
						</EuiFlexItem>
					)}
					<EuiFlexItem>
						<EuiFlexGroup direction="row" gutterSize="s">
							<EuiFlexItem>
								<EuiButton
									disabled={isCancelled || isCompleted}
									iconType="check"
									isLoading={isLoading}
									onClick={(data) =>
										updateStatus(
											data,
											!isConfirmed && isOpen
												? 'confirmed'
												: 'completed',
										)
									}
									size="s"
								>
									{t(
										!isCompleted && isOpen
											? 'general.confirm'
											: 'general.complete',
									)}
								</EuiButton>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiButton
									color="danger"
									disabled={!isOpen}
									iconType="crossInACircleFilled"
									onClick={(data) =>
										updateStatus(data, 'cancelled')
									}
									size="s"
								>
									{t('general.cancel')}
								</EuiButton>
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiFlexItem>
				</EuiFlexGroup>
			}
			stickyHeader
			title={t('orders.orderTitle', { id: order.orderId })}
		>
			<EuiTitle size="xs">
				<h3>{t('orders.orderSummary')}</h3>
			</EuiTitle>
			<EuiHorizontalRule margin="xs" />
			<EuiFlexGroup direction="row" justifyContent="spaceBetween">
				<EuiFlexItem style={{ maxWidth: 750 }}>
					<EuiForm>
						<EuiFlexGroup>
							<EuiFlexItem>
								<EuiFormRow label={t('orders.orderNotes')}>
									<EuiTextArea
										data-testid="notes-input"
										id="notes"
										name="notes"
										onChange={handleChange}
										value={notes}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							{priceEnabled && (
								<EuiFlexItem>
									<EuiFormRow
										label={t('orders.discountType')}
									>
										<EuiSuperSelect
											id="discountType"
											name="discountType"
											onChange={(value) =>
												setFieldValue(
													'discountType',
													value,
												)
											}
											options={discountOpts}
											valueOfSelected={discountType}
										/>
									</EuiFormRow>
									<EuiFormRow
										error={errors.discountValue}
										isInvalid={!!errors.discountValue}
										label={t('orders.discountValue')}
									>
										<EuiFieldNumber
											id="discountValue"
											isInvalid={
												touched.discountValue &&
												!!errors.discountValue
											}
											max={
												discountType === 'percent'
													? 100
													: lineItemsSubtotal
											}
											min={0}
											name="discountValue"
											onChange={handleChange}
											value={discountValue}
										/>
									</EuiFormRow>
								</EuiFlexItem>
							)}
						</EuiFlexGroup>
					</EuiForm>
				</EuiFlexItem>
				{priceEnabled && (
					<EuiFlexItem style={{ maxWidth: 300 }}>
						<EuiDescriptionList
							listItems={[
								{
									title: t('orders.subtotal'),
									description: `P ${numeral(
										lineItemsSubtotal,
									).format('0,0.00')}`,
								},
								{
									title: t('orders.discount'),
									description: `P ${numeral(
										discountSubtotal,
									).format('0,0.00')}`,
								},
								{
									title: t('orders.vat'),
									description: `P ${numeral(vatAmount).format(
										'0,0.00',
									)}`,
								},
								{
									title: t('orders.total'),
									description: `P ${numeral(
										totalAmount,
									).format('0,0.00')}`,
								},
							]}
							type="column"
						/>
					</EuiFlexItem>
				)}
			</EuiFlexGroup>
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />

			<EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
				{selected.length ? (
					<EuiFlexItem grow={false}>
						<EuiButton
							color="danger"
							fill={false}
							iconType="trash"
							size="s"
						>
							Delete
						</EuiButton>
					</EuiFlexItem>
				) : null}
				<EuiFlexItem>
					<EuiTitle size="xs">
						<h1>{t('orders.lineItems')}</h1>
					</EuiTitle>
				</EuiFlexItem>
				<EuiFlexItem>
					<EuiFlexGroup gutterSize="s" justifyContent="flexEnd">
						<EuiFlexItem grow={false}>
							<EuiButton
								disabled={isCompleted || isCancelled}
								fullWidth={false}
								iconType="plusInCircle"
								onClick={() => setIsLineModalVisible(true)}
								size="s"
							>
								{t('orders.addLineItem')}
							</EuiButton>
						</EuiFlexItem>
					</EuiFlexGroup>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiHorizontalRule margin="xs" />
			<Table
				columns={columns}
				isExpandable
				isSelectable
				itemId="id"
				items={lineItems}
				selection={selection}
			/>
			{lineModal}
			{confirmDeleteModal}
			{confirmCancelChangesModal}
		</Page>
	);
};

export default Order;
