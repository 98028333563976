import React, {
	useState,
	useCallback,
	useImperativeHandle,
	forwardRef,
} from 'react';

import { EuiComboBox, EuiText, EuiHealth } from '@elastic/eui';
import _ from 'lodash';

import PropTypes from 'prop-types';

import { getProducts } from 'modules/products/products.fetch';

// eslint-disable-next-line react/prop-types
const ProductSearchComp = ({ onSelect, ...props }, ref) => {
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);
	const [loading, setLoading] = useState(false);

	useImperativeHandle(ref, () => ({
		clear: () => {
			setOptions([]);
			setSelected([]);
		},
		setValue: (value) => {
			setOptions([value]);
			setSelected([value]);
		},
	}));

	const getOptions = async (value) => {
		setLoading(true);
		setOptions([]);

		const { data } = await getProducts({ _q: value });

		if (data) {
			setOptions(
				data.map((d) => ({
					...d,
					label: d.name,
				})),
			);
			setLoading(false);
		}
	};

	const debouncedSearch = useCallback(_.debounce((v) => getOptions(v), 500));

	const onSearchChange = (value) => debouncedSearch(value);

	const renderOption = (option) => {
		return (
			<>
				<strong>{option.label}</strong>
				<EuiText color="subdued" size="s">
					<p className="euiTextColor--subdued">
						{option.drNumber && <EuiHealth color="success" />}
						{option.generic}
						{option.formulation ? ` (${option.formulation})` : ''}
					</p>
				</EuiText>
			</>
		);
	};

	return (
		<EuiComboBox
			async
			className="product-search"
			isLoading={loading}
			onChange={(e) => {
				const [selectedOption] = e;
				if (!selectedOption) {
					return setSelected([]);
				}

				if (onSelect) {
					onSelect(selectedOption);
				}

				return setSelected(e);
			}}
			onSearchChange={onSearchChange}
			options={options}
			placeholder="Search Product"
			renderOption={renderOption}
			rowHeight={50}
			selectedOptions={selected}
			singleSelection={{ asPlainText: true }}
			// eslint-disable-next-line
			{...props}
		/>
	);
};

const ProductSearch = forwardRef(ProductSearchComp);

ProductSearch.propTypes = {
	onSelect: PropTypes.func.isRequired,
};

export default ProductSearch;
