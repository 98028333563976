import React, { useEffect, useState } from 'react';

import {
	EuiLink,
	EuiText,
	EuiLoadingSpinner,
	EuiModal,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiOverlayMask,
	EuiModalBody,
	EuiDescriptionList,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import VerificationStatus from 'components/VerificationStatus';
import { getDoctor } from './global.fetch';

const DoctorQuickView = ({ doctorId }) => {
	const [doctor, setDoctor] = useState(null);
	const [modalOpen, setModalOpen] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchDoctor = async () => {
			try {
				setLoading(true);

				const { data } = await getDoctor(doctorId);

				setDoctor(data);
			} catch {
				setDoctor(null);
			} finally {
				setLoading(false);
			}
		};

		if (doctorId) fetchDoctor();
	}, [doctorId]);

	if (loading || !doctorId || !doctor) {
		return <EuiLoadingSpinner />;
	}

	let doctorName = `No Doctor (Patient Initiated)`;

	if (doctor) {
		doctorName = `Dr. ${doctor.firstName} ${doctor.lastName}`;
	}

	const toggleModal = () => {
		setModalOpen(!modalOpen);
	};

	const renderLicenses = () => {
		return (
			<EuiDescriptionList
				listItems={[
					{
						title: 'PRC License',
						description: (
							<VerificationStatus verified={doctor.prcVerified} />
						),
					},
					{
						title: 'S2 License',
						description: (
							<VerificationStatus verified={doctor.s2Verified} />
						),
					},
					{
						title: 'Medalert Verified',
						description: (
							<VerificationStatus
								verified={doctor.medalertVerified}
							/>
						),
					},
				]}
			/>
		);
	};

	return (
		<>
			<EuiLink onClick={toggleModal}>
				<EuiText>
					{`${doctorName} `}
					<VerificationStatus iconOnly verified={doctor.isVerified} />
				</EuiText>
			</EuiLink>
			{modalOpen && (
				<EuiOverlayMask onClick={toggleModal}>
					<EuiModal onClose={toggleModal}>
						<EuiModalHeader>
							<EuiModalHeaderTitle>
								{doctorName}
							</EuiModalHeaderTitle>
						</EuiModalHeader>
						<EuiModalBody>{renderLicenses()}</EuiModalBody>
					</EuiModal>
				</EuiOverlayMask>
			)}
		</>
	);
};

DoctorQuickView.propTypes = {
	doctorId: PropTypes.string.isRequired,
};

export default DoctorQuickView;
