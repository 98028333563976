import React, { useEffect, useState } from 'react';

import { EuiComboBox } from '@elastic/eui';

import PropTypes from 'prop-types';

import { getBranchesByVendor } from 'modules/branches/branches.fetch';

const BranchDropdown = ({ onChange, pickupOnly, vendor, ...props }) => {
	const [options, setOptions] = useState([]);
	const [selected, setSelected] = useState([]);
	const [loading, setLoading] = useState(false);
	const handleChange = (e) => {
		const [selectedOption] = e;

		if (!selectedOption) {
			if (onChange) onChange([]);
			return setSelected([]);
		}

		if (onChange) onChange(selectedOption);
		return setSelected(e);
	};

	useEffect(() => {
		const processBranchOptions = (branch) => ({
			value: branch.id,
			label: `${branch.name} ${
				branch.address ? `- ${branch.address.city}` : ''
			}`,
		});

		async function fetchBranches() {
			if (!vendor) return;

			try {
				setLoading(true);
				const { data } = await getBranchesByVendor(vendor, pickupOnly);
				const opts = data ? data.map(processBranchOptions) : [];

				setOptions(opts);
			} catch (err) {
				setOptions([]);
			} finally {
				setLoading(false);
			}
		}

		fetchBranches();
	}, [vendor]);

	return (
		<EuiComboBox
			async
			isLoading={loading}
			onChange={handleChange}
			options={options}
			placeholder="Select branch"
			selectedOptions={selected}
			singleSelection={{ asPlainText: true }}
			// eslint-disable-next-line react/jsx-props-no-spreading
			{...props}
		/>
	);
};

BranchDropdown.propTypes = {
	onChange: PropTypes.func.isRequired,
	pickupOnly: PropTypes.bool,
	vendor: PropTypes.string,
};

BranchDropdown.defaultProps = {
	pickupOnly: false,
	vendor: null,
};

export default BranchDropdown;
