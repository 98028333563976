import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Page from 'components/Page';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
	EuiTitle,
	EuiSpacer,
	EuiFlexGroup,
	EuiButton,
	EuiFlexItem,
	EuiSuperSelect,
	EuiButtonEmpty,
	EuiForm,
	EuiFormRow,
	EuiHorizontalRule,
	EuiImage,
	EuiFieldText,
	EuiFieldPassword,
	EuiFilePicker,
	EuiPanel,
} from '@elastic/eui';
import _ from 'lodash';
import DropdownOptWSub from 'components/DropdownOptWSub';
import NoProfilePhoto from 'assets/images/no-profile-photo.png';
import { addToast } from '../dashboard/dashboard.actions';
import { getUser } from './users.fetch';

const UserSchema = Yup.object().shape({
	firstName: Yup.string().required('First Name is required'),
	lastName: Yup.string().required('Last Name is required'),
	username: Yup.string()
		.min(4, 'Username must be minimum of 4 characters')
		.max(50, 'Too Long!')
		.required('Required'),
	email: Yup.string().email('Invalid email').required('Required'),
	password: Yup.string()
		.min(8, 'Password must be minimum of 8 characters')
		.max(50, 'Too Long!')
		.required('Required'),
});

const User = () => {
	const { t } = useTranslation();
	const { userId } = useParams();
	const dispatch = useDispatch();
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [user, setUser] = useState({});
	// eslint-disable-next-line no-unused-vars
	const [hasChanges, setHasChanges] = useState(false);

	const {
		errors,
		handleChange,
		handleSubmit,
		initialValues,
		resetForm,
		setFieldValue,
		touched,
		values,
	} = useFormik({
		initialValues: {
			id: userId,
			username: user.username,
			email: user.email,
			password: user.password,
			firstName: user.firstName,
			middleName: user.middleName,
			lastName: user.lastName,
			address: user.address,
			addressLine1: user.addressLine1,
			addressLine2: user.addressLine2,
			attachments: user.attachments || [],
			role: user.role || {
				type: '',
			},
			licenseNumber: user.licenseNumber ? user.licenseNumber : '',
			branch: user.branch,
		},
		validationSchema: UserSchema,
		validateOnBlur: true,
		enableReinitialize: true,
		// eslint-disable-next-line no-unused-vars
		onSubmit: async (data) => {
			try {
				setIsLoading(true);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		setHasChanges(_.isEqual(initialValues, values));
	}, [values]);

	const {
		attachments,
		email,
		// eslint-disable-next-line no-unused-vars
		firstName,
		// eslint-disable-next-line no-unused-vars
		lastName,
		// eslint-disable-next-line no-unused-vars
		licenseNumber,
		// eslint-disable-next-line no-unused-vars
		middleName,
		password,
		role,
		username,
	} = values;

	useEffect(() => {
		async function fetchUser() {
			try {
				const result = await getUser(userId);
				setUser(result.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchUser();
	}, [userId]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}
		if (error) toastError();
	}, [error]);

	// eslint-disable-next-line no-unused-vars
	const handleDelete = (data) => {};

	// eslint-disable-next-line no-unused-vars
	const handleCancel = () => {
		resetForm({ values: initialValues });
	};

	const handleFilePicker = (event) => {
		const { length, ...fileItems } = event;
		if (!length) return setFieldValue('attachments', []);

		const files = Object.keys(fileItems).map((key) => event[key]);
		setFieldValue(
			'attachments',
			files.map((file) => file.name),
		);

		return setFieldValue('attachments', files);
	};

	const renderFiles = (files) => {
		if (!files.length) {
			return (
				<EuiFlexItem>
					<EuiPanel grow={false}>
						<EuiImage size="s" url={NoProfilePhoto} />
					</EuiPanel>
				</EuiFlexItem>
			);
		}

		const toDisplay = files.map((file) => {
			const url = URL.createObjectURL(file);

			return (
				<EuiFlexItem key={file.name} alignItems="center">
					<EuiPanel grow={false}>
						<EuiImage
							allowFullScreen={!file.type === 'application/pdf'}
							size="s"
							url={url || NoProfilePhoto}
						/>
					</EuiPanel>
				</EuiFlexItem>
			);
		});

		return toDisplay;
	};

	const roleOpts = [
		{
			value: 'superAdmin',
			inputDisplay: t('users.superAdmin'),
			dropdownDisplay: (
				<DropdownOptWSub
					// description={t('users.administratorDescription')}
					title={t('users.superAdmin')}
				/>
			),
		},
		{
			value: 'administrator',
			inputDisplay: t('users.administrator'),
			dropdownDisplay: (
				<DropdownOptWSub
					// description={t('users.administratorDescription')}
					title={t('users.administrator')}
				/>
			),
		},
		{
			value: 'doctor',
			inputDisplay: t('users.doctor'),
			dropdownDisplay: (
				<DropdownOptWSub
					// description={t('users.pharmacistDescription')}
					title={t('users.doctor')}
				/>
			),
		},
		{
			value: 'pharmacist',
			inputDisplay: t('users.pharmacist'),
			dropdownDisplay: (
				<DropdownOptWSub
					// description={t('users.pharmacistDescription')}
					title={t('users.pharmacist')}
				/>
			),
		},
	];

	// eslint-disable-next-line no-unused-vars
	const branchOpts = [
		{
			value: 'Quezon City',
			inputDisplay: 'Quezon City',
			dropdownDisplay: (
				<DropdownOptWSub
					description="Quezon City"
					// title={t('products.percent')}
				/>
			),
		},
		{
			value: 'Manila',
			inputDisplay: 'Manila',
			dropdownDisplay: (
				<DropdownOptWSub
					description="Manila"
					// title={t('products.amount')}
				/>
			),
		},
	];

	// eslint-disable-next-line no-unused-vars
	const disableButton = Object.keys(errors).length > 0;

	return (
		<Page
			headerRight={
				<EuiFlexGroup direction="row" gutterSize="m">
					<EuiFlexItem>
						<EuiButtonEmpty
							color="danger"
							disabled={isLoading || hasChanges}
							iconType="crossInACircleFilled"
							onClick={handleCancel}
						>
							{t('general.cancel')}
						</EuiButtonEmpty>
					</EuiFlexItem>
					<EuiFlexItem>
						<EuiButton
							disabled={isLoading || hasChanges}
							fill
							iconType="checkInCircleFilled"
							isLoading={isLoading}
							onClick={handleSubmit}
						>
							{t('general.saveChanges')}
						</EuiButton>
					</EuiFlexItem>
				</EuiFlexGroup>
			}
			stickyHeader
			title={t('users.title', { id: user.Id })}
		>
			<EuiTitle size="xs">
				<h3>{user.name}</h3>
			</EuiTitle>
			<EuiSpacer size="m" />
			<EuiHorizontalRule margin="xs" />
			<EuiFlexGroup direction="row">
				<EuiFlexItem style={{ maxWidth: 1000 }}>
					<EuiForm>
						<EuiFlexGroup>
							<EuiFlexItem
								alignItems="center"
								grow={false}
								style={{ maxWidth: 150, minHeight: 150 }}
							>
								{renderFiles(attachments)}

								<EuiSpacer />
								<EuiFormRow
								// error={
								// 	touched.attachments &&
								// 	errors.attachments
								// }
								// isInvalid={
								// 	touched.attachments &&
								// 	!!errors.attachments
								// }
								>
									<EuiFilePicker
										// isInvalid={
										// 	touched.attachments &&
										// 	!!errors.attachments
										// }
										display="default"
										onChange={handleFilePicker}
									/>
								</EuiFormRow>
							</EuiFlexItem>

							{/* <EuiFlexItem>
								<EuiFormRow
									error={
										touched.firstName && errors.firstName
									}
									isInvalid={
										touched.firstName && !!errors.firstName
									}
									label="First Name"
								>
									<EuiFieldText
										data-testid="firstName-input"
										id="firstName"
										isInvalid={
											touched.firstName &&
											!!errors.firstName
										}
										name="firstName"
										onChange={handleChange}
										placeholder="First Name"
										value={firstName}
									/>
								</EuiFormRow>
								<EuiFormRow
									// error={errors.middleName}
									// isInvalid={!!errors.middleName}
									label="Middle Name"
								>
									<EuiFieldText
										data-testid="middleName-input"
										id="middleName"
										// isInvalid={!!errors.middleName}
										name="middleName"
										onChange={handleChange}
										placeholder="Middle Name"
										value={middleName}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={touched.lastName && errors.lastName}
									isInvalid={
										touched.lastName && !!errors.lastName
									}
									label="Last Name"
								>
									<EuiFieldText
										data-testid="lastName-input"
										id="lastName"
										isInvalid={
											touched.lastName &&
											!!errors.lastName
										}
										name="lastName"
										onChange={handleChange}
										placeholder="Last Name"
										value={lastName}
									/>
								</EuiFormRow>
							</EuiFlexItem> */}
							<EuiFlexItem>
								<EuiFormRow
									error={touched.username && errors.username}
									isInvalid={
										touched.username && !!errors.username
									}
									label={t('users.username')}
									type="column"
								>
									<EuiFieldText
										data-testid="username-input"
										id="username"
										isInvalid={
											touched.username &&
											!!errors.username
										}
										name="username"
										onChange={handleChange}
										value={username}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={touched.email && errors.email}
									isInvalid={touched.email && !!errors.email}
									label={t('users.email')}
								>
									<EuiFieldText
										data-testid="email-input"
										id="email"
										isInvalid={
											touched.email && !!errors.email
										}
										name="email"
										onChange={handleChange}
										value={email}
									/>
								</EuiFormRow>
								<EuiFormRow
									error={touched.password && errors.password}
									isInvalid={
										touched.password && !!errors.password
									}
									label={t('users.password')}
								>
									<EuiFieldPassword
										data-testid="password-input"
										id="password"
										isInvalid={
											touched.password &&
											!!errors.password
										}
										name="password"
										onChange={handleChange}
										placeholder="Password"
										value={password}
									/>
								</EuiFormRow>
							</EuiFlexItem>
							<EuiFlexItem>
								<EuiFormRow label={t('users.role')}>
									<EuiSuperSelect
										id="role.type"
										name="role.type"
										onChange={(value) =>
											setFieldValue('role.type', value)
										}
										options={roleOpts}
										valueOfSelected={role.type}
									/>
								</EuiFormRow>
								{role === 'Pharmacist' ? (
									<EuiFormRow
										label={t('users.licenseNumber')}
									>
										<EuiFieldText
											data-testid="licenseNumber-input"
											id="licenseNumber"
											name="licenseNumber"
											onChange={handleChange}
											value={licenseNumber}
										/>
									</EuiFormRow>
								) : (
									''
								)}
								{/* <EuiFormRow label={t('users.branch')}>
									<EuiSuperSelect
										id="branch"
										name="branch"
										onChange={(value) =>
											setFieldValue('branch', value)
										}
										options={branchOpts}
										valueOfSelected={branch}
									/>
								</EuiFormRow> */}
							</EuiFlexItem>
							{/* <EuiFlexItem>
								<EuiFormRow label={t('general.address.line1')}>
									<EuiFieldText
										data-testid="address.line1-input"
										id="address.line1"
										name="address.line1"
										onChange={handleChange}
										value={address ? address.line1 : ''}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('general.address.line2')}>
									<EuiFieldText
										data-testid="address.line2-input"
										id="address.line2"
										name="address.line2"
										onChange={handleChange}
										value={address ? address.line2 : ''}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('general.address.city')}>
									<EuiFieldText
										data-testid="city-input"
										id="city"
										name="city"
										onChange={handleChange}
										value={address ? address.city : ''}
									/>
								</EuiFormRow>
								<EuiFormRow label={t('general.address.postal')}>
									<EuiFieldText
										data-testid="city-input"
										id="city"
										name="city"
										onChange={handleChange}
										value={address ? address.postal : ''}
									/>
								</EuiFormRow>
							</EuiFlexItem> */}
						</EuiFlexGroup>
					</EuiForm>
				</EuiFlexItem>
			</EuiFlexGroup>
			<EuiSpacer size="m" />
			<EuiHorizontalRule margin="xs" />
			<EuiSpacer size="m" />

			{/* <EuiFlexGroup alignItems="center" justifyContent="spaceBetween">
				<EuiFlexItem>
					<EuiTitle size="xs">
						<h1>{t('products.productLogs')}</h1>
					</EuiTitle>
				</EuiFlexItem>
			</EuiFlexGroup>
			<Table
				columns={columns}
				isExpandable
				isSelectable
				itemId="id"
				items={productLogs}
				search={search}
				selection={selection}
			/> */}
		</Page>
	);
};

export default User;
