import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
	EuiSpacer,
	EuiTitle,
	EuiModal,
	EuiOverlayMask,
	EuiModalHeader,
	EuiModalHeaderTitle,
	EuiModalBody,
	EuiDescriptionList,
	EuiModalFooter,
	EuiButtonEmpty,
	EuiButton,
} from '@elastic/eui';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';

import { addToast } from 'modules/dashboard/dashboard.actions';
import ConfirmationModal from 'components/ConfirmationModal';

import { createOrder } from '../../orders/orders.fetch';

const ConvertPrescriptionModal = ({ onClose, rxData, visible }) => {
	const { t } = useTranslation();
	const dispatch = useDispatch();
	const history = useHistory();
	const { auth } = useSelector((state) => ({
		auth: state.auth,
	}));
	const { user } = auth;
	const [isLoading, setIsLoading] = useState(false);
	const [error, setError] = useState(null);
	const [
		isConfirmCreateOrderModalVisible,
		setIsConfirmCreateOrderModalVisible,
	] = useState(false);

	const { handleSubmit } = useFormik({
		initialValues: rxData,
		enableReinitialize: true,
		onSubmit: async (data) => {
			setIsLoading(true);
			setIsConfirmCreateOrderModalVisible(false);
			try {
				const newLineItems = data.prescriptionItems.map((rxItem) => ({
					itemName: rxItem.generic,
					brand: rxItem.brand,
					description: rxItem.formulation,
					quantity: rxItem.currentQty || rxItem.quantity,
					price: 0,
					rxItem: rxItem.id,
				}));

				const payload = {
					prescription: data.id,
					patient: data.patient.id,
					vendor: user.vendor,
					address: data.patient.address,
					discountType: data.discountType,
					discountValue: data.discountValue,
					lineItems: newLineItems,
					notes: data.notes,
					pickup: data.pickup,
				};

				const result = await createOrder(payload);

				history.push(`/orders/${result.data.id}`);

				dispatch(
					addToast('Success', 'Order created', 'success', 'check'),
				);
			} catch (err) {
				setError(err.message || err);
			} finally {
				onClose();
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
		}

		if (error) toastError();
	}, [error]);

	const { branch, code, doctor, patient, pickup, prescriptionItems } = rxData;

	const prescriptionItemsList = prescriptionItems.map((prescriptionItem) => {
		return {
			title: `${prescriptionItem.generic}${
				prescriptionItem.brand ? ` - ${prescriptionItem.brand}` : ''
			}`,
			description: `
				${prescriptionItem.formulation || ''} 
				x${prescriptionItem.currentQty || prescriptionItem.quantity}`,
		};
	});

	const descriptionList = [
		{
			title: 'Prescription Code',
			description: code,
		},
		{
			title: 'Patient Name',
			description: `${patient.firstName} ${patient.lastName}`,
		},
	];

	if (doctor) {
		descriptionList.push({
			title: 'Doctor',
			description: `${doctor.firstName} ${doctor.lastName}`,
		});
	}

	if (!doctor) {
		descriptionList.push({
			title: 'Doctor',
			description: `No Doctor (Patient Initiated)`,
		});
	}

	if (pickup) {
		descriptionList.push({
			title: 'Pick Up',
			description: branch ? branch.name : '',
		});
	}

	if (!pickup) {
		const { address } = patient;
		let localAddress = '--';

		if (address) {
			localAddress = `${address.line1} ${address.line2 || ''} ${
				address.city
			}`;
		}

		descriptionList.push({
			title: 'Shipping Address',
			description: localAddress,
		});
	}

	const confirmCreateOrderModal = isConfirmCreateOrderModalVisible ? (
		<ConfirmationModal
			message="This will create an order based on the encoded prescription items"
			onClose={() => {
				setIsConfirmCreateOrderModalVisible(false);
			}}
			onConfirm={handleSubmit}
			title="Confirm Create Order"
		/>
	) : null;

	if (!visible) return null;

	return (
		<EuiOverlayMask>
			<EuiModal
				onClose={onClose}
				// TODO: move to stylesheet
				style={{ position: 'relative', zIndex: 1 }}
			>
				<EuiModalHeader>
					<EuiModalHeaderTitle>Create Order</EuiModalHeaderTitle>
				</EuiModalHeader>
				<EuiModalBody>
					<EuiDescriptionList
						listItems={descriptionList}
						style={{ maxWidth: '600px' }}
						type="column"
					/>

					<EuiSpacer size="xl" />

					<EuiTitle size="s">
						<h3>Prescription Items</h3>
					</EuiTitle>

					<EuiSpacer />

					<EuiDescriptionList
						listItems={prescriptionItemsList}
						style={{ maxWidth: '600px' }}
						type="responsiveColumn"
					/>
				</EuiModalBody>
				<EuiModalFooter>
					<EuiButtonEmpty color="danger" onClick={onClose}>
						{t('general.cancel')}
					</EuiButtonEmpty>

					<EuiButton
						color="primary"
						fill
						isLoading={isLoading}
						onClick={setIsConfirmCreateOrderModalVisible}
					>
						Create Order
					</EuiButton>
				</EuiModalFooter>
			</EuiModal>
			{confirmCreateOrderModal}
		</EuiOverlayMask>
	);
};

ConvertPrescriptionModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	// eslint-disable-next-line
	rxData: PropTypes.object.isRequired,
	visible: PropTypes.bool,
};

ConvertPrescriptionModal.defaultProps = {
	visible: false,
};

export default ConvertPrescriptionModal;
