import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { EuiFlexItem, EuiDatePicker, EuiLink, EuiButton } from '@elastic/eui';

import moment from 'moment';
import _ from 'lodash';

import { ROLE_PATH } from 'constants/protect';
import Page from 'components/Page';
import Table from 'components/Table';
import { ADMIN, DOCTOR, SUPER_ADMIN } from 'components/roles';
import { currencyFormat, formatDate } from 'utils/helpers';
import ContactDetails from 'modules/_global/ContactDetails';

import OrderStatus from './components/OrderStatus';
import AddOrder from './AddOrder';
import { addToast } from '../dashboard/dashboard.actions';
import { getOrders, createOrder, getOrdersByParameters } from './orders.fetch';

const Orders = () => {
	const { t } = useTranslation();
	const { auth, role } = useSelector((state) => ({
		auth: state.auth,
		role: _.get(state, ROLE_PATH),
	}));
	const { vendor } = auth.user;
	const dispatch = useDispatch();
	const [items, setItems] = useState([]);
	const [date, setDate] = useState('');
	const [error, setError] = useState(null);
	// eslint-disable-next-line no-unused-vars
	const [isLoading, setIsLoading] = useState(false);
	const [addOrderVisible, setAddOrderVisible] = useState(false);
	const [filter, setFilter] = useState('');

	useEffect(() => {
		async function fetchOrders() {
			try {
				setIsLoading(true);
				const results = await getOrders();
				setItems(results.data);
			} catch (err) {
				setError(err.message || 'Something went wrong');
			} finally {
				setError(null);
				setIsLoading(false);
			}
		}
		fetchOrders();
	}, []);

	useEffect(() => {
		async function fetchOrderByDate() {
			if (date) {
				try {
					setIsLoading(true);
					const results = await getOrdersByParameters({
						createdAt_gte: moment(date).startOf('day').format(),
						createdAt_lte: moment(date).endOf('day').format(),
					});
					setItems(results.data);
				} catch (err) {
					setError(err.message || 'Something went wrong');
				} finally {
					setError(null);
					setIsLoading(false);
				}
			}
		}

		fetchOrderByDate();
	}, [date]);

	useEffect(() => {
		function toastError() {
			dispatch(addToast('Error', error, 'danger', 'help'));
			setTimeout(() => {
				setError(null);
			}, 2000);
		}
		if (error) toastError();
	}, [error]);

	const addOrder = async (data) => {
		try {
			setIsLoading(true);
			const payload = {
				patient: {
					firstName: data.firstName,
					middleName: data.middleName,
					lastName: data.lastName,
					contactDetails: {
						type: 'mobile',
						value: data.contactDetails,
					},
					address: {
						line1: data.line1,
						line2: data.line2,
						city: data.city,
						state: data.state,
						postal: data.postal,
					},
				},
				discountType: data.discountType,
				discountValue: data.discountValue,
				vendor: vendor.id,
				lineItems: data.lineItems,
				orderNotes: data.orderNotes,
			};
			const result = await createOrder(payload);
			const updatedItems = [...items, result.data];
			setItems(updatedItems);
			dispatch(addToast('Success', 'Order added', 'success', 'check'));
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
			setAddOrderVisible(false);
		}
	};

	const clearFilters = async () => {
		setDate(null);

		try {
			setIsLoading(false);
			const results = await getOrders();
			setFilter('');
			setItems(results.data);
		} catch (err) {
			setError(err.message || 'Something went wrong');
		} finally {
			setError(null);
			setIsLoading(false);
		}
	};

	const renderToolsRight = () => [
		<EuiFlexItem key="datePicker" grow={false} justifyContent="center">
			<EuiDatePicker
				onChange={(e) => setDate(formatDate(e))}
				placeholder={t('orders.startDate')}
				value={date}
			/>
		</EuiFlexItem>,
		<EuiFlexItem key="clearFilter" grow={false} justifyContent="center">
			<EuiButton
				color="secondary"
				iconType="broom"
				onClick={clearFilters}
			>
				Clear Filters
			</EuiButton>
		</EuiFlexItem>,
	];

	const statuses = ['open', 'confirmed', 'completed', 'cancelled'];

	const onQueryChange = async ({ query }) => {
		try {
			setIsLoading(true);
			const params = {};

			const values = query.text.length ? query.text.split(' ') : [];

			values.forEach((v) => {
				if (v.indexOf(':') > -1) {
					const [prop, value] = v.split(':');
					params[prop] = value;
				}
			});

			const results = await getOrdersByParameters(params);

			setFilter(query.text);
			setItems(results.data);
		} catch {
			setItems([]);
		} finally {
			setIsLoading(false);
		}
	};

	const search = {
		query: filter,
		box: {
			incremental: true,
		},
		toolsRight: renderToolsRight(),
		filters: [
			{
				type: 'field_value_selection',
				field: 'status',
				name: 'Status',
				multiSelect: false,

				options: statuses.map((status) => ({
					value: status,
					name: status,
					view: <OrderStatus status={status} />,
				})),
			},
			{
				type: 'field_value_selection',
				field: 'pickup',
				name: 'Fulfillment',
				multiSelect: false,
				options: [
					{
						value: false,
						name: 'For Delivery',
					},
					{
						value: true,
						name: 'For Pick Up',
					},
				],
			},
		],
		onChange: onQueryChange,
	};

	const columns = [
		{
			field: 'orderId',
			name: 'Order ID',
			sortable: true,
			render: (orderId, { id }) => (
				<Link to={`/orders/${id}`}>
					<EuiLink>{orderId}</EuiLink>
				</Link>
			),
			width: '15%',
		},
		{
			field: 'patient.firstName',
			name: 'Patient',
			sortable: true,
			render: (firstName, { patient: { lastName } }) =>
				`${firstName} ${lastName}`,
		},
		{
			field: 'patient.contactDetails',
			name: 'Contact Details',
			sortable: false,
			render: (ids) => <ContactDetails ids={ids} />,
		},
		{
			field: 'status',
			name: 'Status',
			sortable: true,
			render: (status) => <OrderStatus status={status} />,
			width: '15%',
		},
		{
			field: 'createdAt',
			name: 'Order Date',
			dataType: 'date',
			sortable: true,
			render: (v) => moment(v).format('ll'),
			width: '10%',
		},
	];

	if (_.get(auth, 'user.vendor.priceEnabled')) {
		columns.push({
			field: 'totalAmount',
			name: 'Total Amount',
			dataType: 'number',
			sortable: true,
			render: currencyFormat,
			width: '15%',
		});
	}

	if (role === ADMIN.key || role === SUPER_ADMIN.key) {
		columns.push({
			field: 'branch.name',
			name: 'Branch',
			sortable: true,
		});
	}

	if (role !== DOCTOR.key) {
		columns.push({
			field: 'pickup',
			name: 'Fulfillment',
			render: (pickup) => (pickup ? 'For Pickup' : 'Delivery'),
		});
	}

	return (
		<>
			<Helmet title={t('orders.title')} />
			<Page title={t('orders.title')}>
				<Table
					columns={columns}
					itemId="orderId"
					items={items}
					search={search}
				/>

				<AddOrder
					addOrder={addOrder}
					isLoading={isLoading}
					onClose={() => setAddOrderVisible(false)}
					setError={setError}
					visible={addOrderVisible}
				/>
			</Page>
		</>
	);
};

export default Orders;
