import React from 'react';
import { EuiConfirmModal, EuiOverlayMask } from '@elastic/eui';

import PropTypes from 'prop-types';

const ConfirmationModal = ({ id, message, onClose, onConfirm, title }) => (
	<EuiOverlayMask key={id}>
		<EuiConfirmModal
			cancelButtonText="Cancel"
			confirmButtonText="Confirm"
			defaultFocusedButton="confirm"
			onCancel={onClose}
			onConfirm={onConfirm}
			title={title}
		>
			<p>{message}</p>
		</EuiConfirmModal>
	</EuiOverlayMask>
);

ConfirmationModal.propTypes = {
	id: PropTypes.string,
	message: PropTypes.string,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	title: PropTypes.string,
};

ConfirmationModal.defaultProps = {
	id: null,
	message: null,
	title: null,
};

export default ConfirmationModal;
