import numeral from 'numeral';
import moment from 'moment';
import { DATE_FORMAT } from 'constants/formats';

export const currencyFormat = (value) => `P ${numeral(value).format('0,0.00')}`;

export const formatDate = (date) => moment(date).format(DATE_FORMAT);

export const buildLocalAddress = (address) => {
	return `${address.line1 || ''} ${address.line2 || ''} ${
		address.brgy || ''
	} ${address.city || ''}`;
};
