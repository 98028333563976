import FormData from 'form-data';
import api from 'utils/api';

export const getPrescriptions = async (params) => {
	return api.get('/prescriptions', { params });
};

export const getPrescription = async (id) => api.get(`/prescriptions/${id}`);

export const getPrescriptionsByParameters = async (payload) =>
	api.post(`/prescriptions/filter-by-date`, payload);

export const updatePrescription = async (id, payload) =>
	api.put(`/prescriptions/${id}`, payload);

export const updatePrescriptionStatus = async (id, status) =>
	api.put(`/prescriptions/${id}/status?status=${status}`);

export const createPrescription = async (payload) => {
	const { attachments, ...data } = payload;
	const formData = new FormData();

	formData.append('data', JSON.stringify(data));
	attachments.forEach((file) => formData.append('files[attachments]', file));

	return api.post('/prescriptions', formData);
};

export const getRxItem = async (id) => api.get(`/prescription-items/${id}`);

export const readPrescription = async (id) => {
	return api.get(`/notifications/prescription/${id}/read`);
};
