/* eslint-disable react/forbid-prop-types */
import React from 'react';

import { EuiFieldText, EuiFormRow } from '@elastic/eui';

import PropTypes from 'prop-types';

import CityMunicipalityDropdown from 'components/forms/CityMunicipalityDropdown';
import BarangayDropdown from 'components/forms/BarangayDropdown';

const NewPatientForm = ({ formikBag }) => {
	const {
		errors,
		handleBlur,
		handleChange,
		setFieldValue,
		touched,
		values,
	} = formikBag;

	return (
		<>
			<EuiFormRow
				error={touched.firstName && errors.firstName}
				isInvalid={touched.firstName && !!errors.firstName}
				label="First Name"
			>
				<EuiFieldText
					id="firstName"
					isInvalid={touched.firstName && !!errors.firstName}
					name="firstName"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.firstName}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.lastName && errors.lastName}
				isInvalid={touched.lastName && !!errors.lastName}
				label="Last Name"
			>
				<EuiFieldText
					id="lastName"
					isInvalid={touched.lastName && !!errors.lastName}
					name="lastName"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.lastName}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.contactNumber && errors.contactNumber}
				isInvalid={touched.contactNumber && !!errors.contactNumber}
				label="Mobile Number"
			>
				<EuiFieldText
					id="contactNumber"
					isInvalid={touched.contactNumber && !!errors.contactNumber}
					name="contactNumber"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.contactNumber}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.line1 && errors.line1}
				isInvalid={touched.line1 && !!errors.line1}
				label="Street Address"
			>
				<EuiFieldText
					id="line1"
					isInvalid={touched.line1 && !!errors.line1}
					name="line1"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.line1}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.line2 && errors.line2}
				isInvalid={touched.line2 && !!errors.line2}
				label="Town / Village / Subd."
			>
				<EuiFieldText
					id="line2"
					isInvalid={touched.line2 && !!errors.line2}
					name="line2"
					onBlur={handleBlur}
					onChange={handleChange}
					value={values.line2}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.brgyId && errors.brgyId}
				isInvalid={touched.brgyId && !!errors.brgyId}
				label="Barangay"
			>
				<BarangayDropdown
					onChange={(e) => {
						if (e) {
							setFieldValue('cityId', e.city.id);
							setFieldValue('city', e.city.name);
							setFieldValue('brgy', e.name);
							return setFieldValue('brgyId', e.value);
						}

						setFieldValue('brgy', e.label);
						return setFieldValue('brgyId', null);
					}}
					value={values.brgyId}
				/>
			</EuiFormRow>
			<EuiFormRow
				error={touched.cityId && errors.cityId}
				isInvalid={touched.cityId && !!errors.cityId}
				label="City / Municipality"
			>
				<CityMunicipalityDropdown
					onChange={(e) => {
						if (e) {
							setFieldValue('city', e.name);
							return setFieldValue('cityId', e.value);
						}

						setFieldValue('city', null);
						return setFieldValue('cityId', null);
					}}
					value={values.cityId}
				/>
			</EuiFormRow>
		</>
	);
};

NewPatientForm.propTypes = {
	formikBag: PropTypes.shape({
		errors: PropTypes.object,
		handleBlur: PropTypes.func,
		handleChange: PropTypes.func,
		setFieldValue: PropTypes.func,
		touched: PropTypes.object,
		values: PropTypes.object,
	}).isRequired,
};

export default NewPatientForm;
