import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';

import {
	EuiButtonEmpty,
	EuiCallOut,
	EuiFieldPassword,
	EuiFlexGroup,
	EuiFlexItem,
	EuiForm,
	EuiFormRow,
	EuiSpacer,
	EuiButton,
	EuiText,
} from '@elastic/eui';
import { useFormik } from 'formik';
import * as yup from 'yup';

import { postResetPassword } from './auth.fetch';

const ResetPasswordSchema = (t) => {
	return yup.object().shape({
		password: yup
			.string()
			.min(8, t('resetPassword.minLength'))
			.required(t('resetPassword.passwordRequired')),
		passwordConfirmation: yup
			.string()
			.required(t('resetPassword.confirmPasswordRequired'))
			.when('password', {
				is: (val) => !!(val && val.length > 0) || false,
				then: yup
					.string()
					.oneOf(
						[yup.ref('password')],
						t('resetPassword.confirmPasswordMatch'),
					),
			}),
	});
};

function ResetPassword() {
	const { t } = useTranslation();
	const history = useHistory();
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const urlParams = new URLSearchParams(window.location.search);
	const code = urlParams.get('code');

	const { errors, handleChange, handleSubmit, values } = useFormik({
		initialValues: {
			password: '',
			passwordConfirmation: '',
		},
		validationSchema: ResetPasswordSchema(t),
		validateOnBlur: true,
		onSubmit: async (data) => {
			try {
				setError(null);
				setIsLoading(true);

				if (!code) {
					throw new Error('Code is missing in the url');
				}

				await postResetPassword({ code, ...data });

				setSuccess(true);
			} catch (err) {
				// set error message from api request failure
				setError(err.message || t('errors.default'));
			} finally {
				setIsLoading(false);
			}
		},
	});

	useEffect(() => {
		if (success) {
			setTimeout(() => history.replace('/login'), 3000);
		}
	}, [success, history]);

	const navigateToLogin = () => history.push('/login');

	const disableButton = Object.keys(errors).length > 0;

	return (
		<>
			<Helmet title={t('resetPassword.title')} />
			<EuiText textAlign="center">
				<h3>{t('resetPassword.title')}</h3>
			</EuiText>
			<EuiSpacer size="s" />
			<EuiForm>
				{error && (
					<>
						<EuiCallOut color="danger" size="s" title={error} />
						<EuiSpacer size="s" />
					</>
				)}
				{success ? (
					<>
						<EuiCallOut
							color="success"
							size="s"
							title={t('resetPassword.successMessage')}
						/>
						<EuiSpacer size="s" />
					</>
				) : (
					<>
						<EuiFormRow
							error={errors.password}
							isInvalid={!!errors.password}
							label={t('resetPassword.passwordLabel')}
						>
							<EuiFieldPassword
								data-testid="password-input"
								id="password"
								isInvalid={!!errors.password}
								name="password"
								onChange={handleChange}
								placeholder={t(
									'resetPassword.passwordPlaceholder',
								)}
								value={values.password}
							/>
						</EuiFormRow>
						<EuiFormRow
							error={errors.passwordConfirmation}
							isInvalid={!!errors.passwordConfirmation}
							label={t('resetPassword.confirmPasswordLabel')}
						>
							<EuiFieldPassword
								data-testid="confirm-password-input"
								id="passwordConfirmation"
								isInvalid={!!errors.passwordConfirmation}
								name="passwordConfirmation"
								onChange={handleChange}
								placeholder={t(
									'resetPassword.confirmPasswordLabel',
								)}
								value={values.passwordConfirmation}
							/>
						</EuiFormRow>
						<EuiButton
							color="primary"
							data-testid="reset-button"
							disabled={disableButton}
							fill
							fullWidth
							isLoading={isLoading}
							onClick={handleSubmit}
							type="submit"
						>
							{t('resetPassword.title')}
						</EuiButton>
					</>
				)}
				<EuiSpacer size="s" />
				<EuiFlexGroup justifyContent="center">
					<EuiFlexItem grow={false}>
						<EuiButtonEmpty
							flush="left"
							onClick={navigateToLogin}
							size="xs"
						>
							{t('resetPassword.backToLogin')}
						</EuiButtonEmpty>
					</EuiFlexItem>
				</EuiFlexGroup>
			</EuiForm>
		</>
	);
}

export default ResetPassword;
