import React, { useEffect, useState } from 'react';

import {
	EuiBadge,
	EuiBadgeGroup,
	EuiLoadingSpinner,
	EuiText,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import { getContactDetailsByIds } from './global.fetch';

const ContactDetails = ({ ids, plain, showType }) => {
	const [details, setDetails] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const getColor = (type) => {
		switch (type) {
			case 'email':
				return '#00805e';

			case 'phone':
				return '#d76e10';

			case 'mobile':
				return '#c0e0ff';

			default:
				return '#d2d2d2';
		}
	};

	useEffect(() => {
		async function getContactDetails() {
			setLoading(true);
			const { data } = await getContactDetailsByIds(ids);
			let result = data;

			if (showType && data) {
				result = data.filter((d) => {
					if (showType) {
						return d.type === showType;
					}

					return d;
				});
			}

			setDetails(result);

			setLoading(false);
		}

		getContactDetails();
	}, [ids]);

	if (isLoading) {
		return <EuiLoadingSpinner />;
	}

	if (!isLoading && (!details || !details.length)) {
		return (
			<EuiText color="subdued" size="s">
				No Contact Details Provided.
			</EuiText>
		);
	}

	if (!isLoading && plain) {
		return (
			<>
				{details.map((d) => (
					<EuiText key={`d-${d.id}`} color={getColor(d.type)}>
						{d.value}
					</EuiText>
				))}
			</>
		);
	}

	return (
		<EuiBadgeGroup gutterSize="xs">
			{details.map((deet) => (
				<EuiBadge key={`deet-${deet.id}`} color={getColor(deet.type)}>
					{deet.value}
				</EuiBadge>
			))}
		</EuiBadgeGroup>
	);
};

ContactDetails.propTypes = {
	ids: PropTypes.arrayOf(PropTypes.string).isRequired,
	plain: PropTypes.bool,
	showType: PropTypes.oneOf(['mobile', 'phone', 'email']),
};

ContactDetails.defaultProps = {
	plain: false,
	showType: null,
};

export default ContactDetails;
