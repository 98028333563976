import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import {
	EuiImage,
	EuiPage,
	EuiPageBody,
	EuiPageContent,
	EuiPageContentBody,
	EuiFlexGroup,
	EuiFlexItem,
} from '@elastic/eui';

import PropTypes from 'prop-types';

import Logo from 'assets/images/logo_medium.png';

function AuthLayout({ children, containerStyle }) {
	const auth = useSelector((state) => state.auth);

	// redirect to dashboard if already logged in
	if (auth.isLoggedIn) {
		return <Redirect to="/" />;
	}

	return (
		<EuiPage
			data-testid="registration-layout-container"
			style={containerStyle}
		>
			<EuiPageBody component="div">
				<EuiPageContent
					className="registration form-container"
					horizontalPosition="center"
					verticalPosition="center"
				>
					<EuiPageContentBody>
						<EuiFlexGroup>
							<EuiFlexItem className="left-column">
								<EuiFlexGroup
									alignItems="center"
									justifyContent="center"
								>
									<EuiImage
										alt="ERx Registration"
										size="m"
										url={Logo}
									/>
								</EuiFlexGroup>
							</EuiFlexItem>
							<EuiFlexItem className="right-column">
								{children}
							</EuiFlexItem>
						</EuiFlexGroup>
					</EuiPageContentBody>
				</EuiPageContent>
			</EuiPageBody>
		</EuiPage>
	);
}

AuthLayout.propTypes = {
	children: PropTypes.node.isRequired,
	containerStyle: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.arrayOf(PropTypes.object),
	]),
};

AuthLayout.defaultProps = {
	containerStyle: null,
};

export default AuthLayout;
